import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { paginate } from 'helpers/paginate';
import routes from 'routes';
import Wrap from 'cms/components/Wrap';
import LinkButton from 'cms/components/Table/LinkButton';
import Table from './Table';
import messages from './messages';
import Skeleton from './Skeleton';
import { makeSelectUser } from 'containers/App/selectors';
import IsFormVisible from 'helpers/IsFormVisible';
import {
  deleteCondition,
  deleteExclusion,
  getCategories,
  getConditions,
  getExclusions
} from './redux/actions';
import {
  makeSelectCategories,
  makeSelectConditions,
  makeSelectExclusions
} from './redux/selectors';
import TableRemove from './TableRemove';

const key = 'lager';

const RecommendedPrices = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const conditions = useSelector(makeSelectConditions());
  const exclusions = useSelector(makeSelectExclusions());

  useEffect(() => {
    dispatch(getConditions());
    dispatch(getExclusions());
  }, [dispatch]);

  const handleDelete = useCallback(
    id => {
      dispatch(deleteCondition(id));
    },
    [dispatch]
  );

  const handleDeleteExclusion = useCallback(
    id => {
      dispatch(deleteExclusion(id));
    },
    [dispatch]
  );

  return (
    <div style={{ display: 'flex', width: '100%', gap: 10 }}>
      <Wrap
        pageTitle={messages.page_title}
        data={conditions?.data}
        isInline={true}
        helperVisible={false}
        type="cms"
        skeleton={<Skeleton className="web_margins" />}
        headerLeftContent={
          <LinkButton
            label={messages.add_criterion}
            link={routes.CMS_ADD_CONDITION}
          />
        }
        tableContent={
          <Table data={conditions?.data} handleDelete={handleDelete} />
        }
      />
      <Wrap
        pageTitle={messages.page_title}
        data={exclusions?.data}
        isInline={true}
        helperVisible={false}
        type="cms"
        skeleton={<Skeleton className="web_margins" />}
        headerLeftContent={
          <LinkButton
            classes={'danger'}
            label={messages.remove_criterion}
            link={routes.CMS_REMOVE_CONDITION}
          />
        }
        tableContent={
          <TableRemove
            data={exclusions?.data}
            handleDelete={handleDeleteExclusion}
          />
        }
      />
    </div>
  );
};

export default RecommendedPrices;
