import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCategories = state => state.dashboard || initialState;

const makeSelectCategories = () =>
  createSelector(selectCategories, substate => substate.categories);

const makeSelectSingleCategory = () =>
  createSelector(selectCategories, substate => substate.singleCategory);

const makeSelectSingleCategoryError = () =>
  createSelector(selectCategories, substate => substate.singleCategoryError);

const makeSelectParams = () =>
  createSelector(selectCategories, substate => substate.params);

const makeSelectCreateCategoryIsPending = () =>
  createSelector(
    selectCategories,
    substate => substate.createCategoryIsPending
  );

const makeSelectUpdateCategoryIsPending = () =>
  createSelector(
    selectCategories,
    substate => substate.updateCategoryIsPending
  );

const makeSelectAttributeValues = () =>
  createSelector(selectCategories, substate => substate.attributeValues);

const makeSelectSearchStats = () =>
  createSelector(selectCategories, substate => substate.searchStats);

export {
  makeSelectCategories,
  makeSelectSingleCategory,
  makeSelectParams,
  makeSelectCreateCategoryIsPending,
  makeSelectUpdateCategoryIsPending,
  makeSelectSingleCategoryError,
  makeSelectAttributeValues,
  makeSelectSearchStats
};
