const KEY = '[CMS_LANDING_PAGE]';

const types = {
  GET_CATALOG: `${KEY} GET_CATALOGS`,
  SET_CATALOG: `${KEY} SET_CATALOG`,
  PICK_CATALOG: `${KEY} PICK_CATALOG`,

  SET_PRODUCTS: `${KEY} SET_PRODUCTS`,

  SUBMIT_LANDING_PAGE: `${KEY} SUBMIT_LANDING_PAGE`,
  GET_ERROR: `${KEY} GET_ERROR`,

  GET_LANDING_PAGE_MODELS: `${KEY} GET_LANDING_PAGE_MODELS`,
  SET_LANDING_PAGE_MODELS: `${KEY} SET_LANDING_PAGE_MODELS`,

  GET_LANDING_PAGES: `${KEY} GET_LANDING_PAGES`,
  SET_LANDING_PAGES: `${KEY} SET_LANDING_PAGES`,

  GET_LANDING_PAGE: `${KEY} GET_LANDING_PAGE`,
  SET_LANDING_PAGE: `${KEY} SET_LANDING_PAGE`,

  UPDATE_LANDING_PAGE: `${KEY} UPDATE_LANDING_PAGE`,
  DELETE_LANDING_PAGE: `${KEY} DELETE_LANDING_PAGE`,

  FETCH_CATEGORIES: `${KEY} FETCH_CATEGORIES`,
  SET_CATEGORIES: `${KEY} SET_CATEGORIES`,

  SUCCESS_EDITOR: `${KEY} SUCCESS_EDITOR`,
  CHANGE_SHOCK_ACTION: `${KEY} CHANGE_SHOCK_ACTION`,
  CHANGE_HOME_PAGE_POSITION: `${KEY} CHANGE_HOME_PAGE_POSITION`
};

export default types;
