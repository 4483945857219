import { defineMessages } from 'react-intl';

export const scope = 'cms_recommended_prices';

export default defineMessages({
  page_title: {
    id: `${scope}.page_title`,
    defaultMessage: 'Preporučene cene'
  },
  condition_created: {
    id: `${scope}.condition_created`,
    defaultMessage: 'Uspešno ste napravili kriterijum.'
  },
  condition_updated: {
    id: `${scope}.condition_updated`,
    defaultMessage: 'Uspešno ste izmenili kriterijum.'
  },
  delete_modal_text: {
    id: `${scope}.delete_modal_stavka`,
    defaultMessage: 'stavku'
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Dodaj'
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Izmeni'
  },
  edit_criterion: {
    id: `${scope}.edit_criterion`,
    defaultMessage: 'Izmeni kriterijum'
  },
  add_criterion: {
    id: `${scope}.add_criterion`,
    defaultMessage: 'Dodaj kriterijum'
  },
  remove_criterion: {
    id: `${scope}.remove_criterion`,
    defaultMessage: 'Izuzmi kriterijum'
  },
  category: {
    id: `${scope}.do`,
    defaultMessage: 'Do dana'
  },
  category_select_label: {
    id: `${scope}.category_select_label`,
    defaultMessage: 'Kategorije'
  },
  category_select_placeholder: {
    id: `${scope}.category_select_placeholder`,
    defaultMessage: 'Kategoriju'
  },
  manufacturer: {
    id: `${scope}.od`,
    defaultMessage: 'Od dana'
  },
  name: {
    id: `${scope}.kategorije`,
    defaultMessage: 'Kategorije'
  },
  actions: {
    id: `${scope}.marza`,
    defaultMessage: 'Marža'
  },
  created: {
    id: `${scope}.created`,
    defaultMessage: 'Uspešno ste napravili preporučenu cenu.'
  },
  updated: {
    id: `${scope}.updated`,
    defaultMessage: 'Uspešno ste izmenili preporučenu cenu.'
  },
  deleted: {
    id: `${scope}.deleted`,
    defaultMessage: 'Uspešno ste izbrisali preporučenu cenu.'
  },
  yup_validation_name_min: {
    id: `${scope}.yup_validation_name_min`,
    defaultMessage: 'Ime mora sadržati minimum 2 karaktera'
  },
  yup_validation_name_max: {
    id: `${scope}.yup_validation_name_max`,
    defaultMessage: 'Ime može sadržati maksimum 255 karaktera'
  },
  yup_validation_manufacturer_required: {
    id: `${scope}.yup_validation_manufacturer_required`,
    defaultMessage: 'Dobavljač je obavezan'
  },
  price404: {
    id: `${scope}.price404`,
    defaultMessage: 'Preporučena cena ne postoji ili je izbrisana.'
  },
  lower: {
    id: `${scope}.brands`,
    defaultMessage: 'Brendovi'
  },
  yup_validation_lower_max: {
    id: `${scope}.yup_validation_lower_max`,
    defaultMessage: 'Maksimalni procenat sniženja je 100%'
  },
  yup_validation_lower_min: {
    id: `${scope}.yup_validation_lower_min`,
    defaultMessage: 'Minimalni procenat sniženja je 0%'
  }
});
