import React from 'react';
import { useIntl } from 'react-intl';

const MobileTableRow = ({ label, children }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="mobile_table_row">
      <div className="row_label">{formatMessage(label)}:</div>
      <div className="row_content">{children}</div>
    </div>
  );
};

export default MobileTableRow;
