/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from '../../redux/reducer';
import saga from '../../redux/saga';
import Update from './Update';
import WrapForm from 'cms/components/WrapForm';
import messages from '../../messages';
import Skeleton from './Skeleton';
import {
  getCondition,
  getExclusion,
  updateCondition,
  updateExclusion
} from 'cms/JakovLager/redux/actions';
import {
  makeSelectCondition,
  makeSelectExclusion
} from 'cms/JakovLager/redux/selectors';

const key = 'lager';

const EditCondition = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const { condition_id } = useParams();
  const dispatch = useDispatch();
  const condition = useSelector(makeSelectExclusion());

  const onSubmit = useCallback(
    (...args) => dispatch(updateExclusion(...args)),
    [dispatch]
  );

  useEffect(() => {
    dispatch(getExclusion(condition_id));
  }, [dispatch]);

  console.log('EXCLUSION', condition);
  return (
    <WrapForm
      data={condition}
      pageTitle={messages.edit_criterion}
      skeleton={<Skeleton />}
    >
      <Update
        id={condition_id}
        data={condition?.data}
        exclusion={true}
        onSubmit={onSubmit}
      />
    </WrapForm>
  );
};

export default EditCondition;
