import React from 'react';

const MobileTableWrap = ({ children }) => {
  return (
    <div className="mobile_table_wrapper" onClick={e => e.stopPropagation()}>
      <div className="mobile_table_holder">{children}</div>
    </div>
  );
};

export default MobileTableWrap;
