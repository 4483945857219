import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import history from 'utils/history';
import request from 'utils/request';
import { getItem, removeItem, setItem } from 'utils/localStorage';
import {
  fetchAuthenticatedUserSuccess,
  logoutSuccess,
  setModules,
  setBanners,
  setUsersAddresses
} from './actions';
import types from './constants';
import routes from 'routes';
import { makeSelectModules } from './selectors';
import { getItems } from 'views/ShoppingCart/redux/actions';
import { getFavoriteItems } from 'views/WishList/redux/actions';
import response from './mockData.json';
// import tokenMock from './tokenMock.json'
const cookies = new Cookies();

export function* fetchUser() {
  try {
    const { data } = yield call(request, {
      url: 'users/token',
      method: 'get'
    });
    // const data = tokenMock.data
    yield put(fetchAuthenticatedUserSuccess(data));
    yield setAddresses(data.addresses);
  } catch (error) {}
}

function* setAddresses(addresses) {
  if (addresses.length) {
    let billing = addresses.find(el => el.type === 'billing_address');
    let shipping = addresses.find(el => el.type === 'shipping_address');
    yield put(
      setUsersAddresses({
        billing_address: billing || {},
        shipping_address: shipping || {}
      })
    );
  } else {
    yield put(setUsersAddresses({ billing_address: {}, shipping_address: {} }));
  }
}

export function* logout() {
  const selectModules = yield select(makeSelectModules());
  const modules = getItem('modules') || selectModules;
  yield call(removeItem, 'token');

  try {
    yield call(request, {
      url: 'logout',
      method: 'post'
    });
    yield put(logoutSuccess());
    if (modules.includes('Frontend')) {
      yield put(push(routes.HOME));
    }
  } catch (error) {}
}

export function* fetchModules() {
  try {
    const res = yield call(request, {
      url: 'modules',
      method: 'get'
    });
    let arr = [];
    Object.entries(res).map(item => arr.push(item[1]));
    yield call(setItem, 'modules', arr);
    yield put(setModules(arr));
  } catch (error) {}
}

export function* fetchBanners() {
  try {
    const { data } = yield call(request, {
      url: 'banners?paginate=100',
      method: 'get'
    });
    yield put(setBanners(data));
    // yield put(setBanners(response.data));
  } catch (error) {}
}

export function* transferCartOnAuth() {
  try {
    yield call(request, {
      url: 'cart/transfer',
      method: 'post',
      data: {
        cart_id: cookies.get('user_id'),
        wishlist_id: cookies.get('favorite_user_id')
      }
    });
  } catch (error) {}
}

export default function* appSaga() {
  yield takeLatest(types.FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(types.LOGOUT_REQUEST, logout);
  yield takeLatest(types.GET_MODULES, fetchModules);
  yield takeLatest(types.GET_BANNERS, fetchBanners);
  yield takeLatest(types.TRANSFER_CART_ON_AUTH, transferCartOnAuth);
}
