/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  _getStaticPagesSEO,
  _updateSEO,
  _updateSEOStatic_Pages
} from './redux/actions';
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize
} from '@material-ui/core';

import Variables from './Variables';
import { makeSelectStaticPages } from './redux/selectors';
const key = 'seo';

const SEO = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const [option, setOption] = useState('static_pages');
  const [title, setTitle] = useState('');
  const [keywordsArray, setKeywordsArray] = useState('');
  const [description, setDescription] = useState('');
  const [meta_robot, setMetaRobot] = useState('index, follow');
  const [url, setURL] = useState('');
  const [keywords, setKeywords] = useState('');
  const [static_pages_seo, setStaticPagesSEO] = useState([]);
  const [selected_static_page, setSelectedStaticPage] = useState({});

  const updateSEO = useCallback((...args) => dispatch(_updateSEO(...args)), [
    dispatch
  ]);

  const updateSEO_Static_Pages_Action = useCallback(
    (...args) => dispatch(_updateSEOStatic_Pages(...args)),
    [dispatch]
  );

  function updateSEO_Static_Pages(params) {
    setStaticPagesSEO([
      ...(static_pages_seo?.filter(i => i?.url !== params?.url) || []),
      params
    ]);

    updateSEO_Static_Pages_Action([
      ...(static_pages_seo?.filter(i => i?.url !== params?.url) || []),
      params
    ]);
  }

  const static_pages = useSelector(makeSelectStaticPages());

  useEffect(() => {
    dispatch(_getStaticPagesSEO());
  }, [selected_static_page]);

  useEffect(() => {
    setURL('');
    setKeywords('');
    setTitle('');
    setDescription('');
    setMetaRobot('index, follow');
  }, [option]);

  return (
    <Card>
      <div className="cms_seo">
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Opcija</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={option}
              label="Opcija"
              onChange={e => setOption(e.target.value)}
            >
              <MenuItem value={'category'}>Kategorije</MenuItem>
              <MenuItem value={'product'}>Proizvodi</MenuItem>
              <MenuItem value={'action'}>Akcije</MenuItem>
              <MenuItem value={'news'}>Vesti</MenuItem>
              <MenuItem value={'static_pages'}>Statičke stranice</MenuItem>
            </Select>
          </FormControl>
          {option === 'static_pages' ? (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <p>Izaberi stranicu:</p>
              <hr />
              {static_pages?.map(page => {
                return (
                  <div
                    onClick={() => {
                      setSelectedStaticPage(page);
                      setTitle(page?.seo_title);
                      setDescription(page?.seo_description);
                      setMetaRobot(page?.seo_meta_robots);
                      setURL(page?.seo_url);
                      setKeywords(page?.seo_keywords);
                    }}
                    style={
                      selected_static_page?.seo_url === page?.seo_url
                        ? {
                            backgroundColor: 'lightgray',
                            marginTop: '5px',
                            cursor: 'pointer'
                          }
                        : { marginTop: '5px', cursor: 'pointer' }
                    }
                  >
                    {page?.seo_url}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="right_side">
          <>
            <>
              {option === 'static_pages' ? (
                <>
                  <b>URL</b>
                  <TextareaAutosize
                    style={{
                      width: '100%',
                      border: '1px solid black',
                      padding: '5px'
                    }}
                    value={url}
                    onChange={e => {
                      setURL(e.target.value);
                    }}
                    placeholder="URL"
                    minRows={1}
                  />{' '}
                </>
              ) : null}
              {option === 'static_pages' ? (
                <>
                  <b>Keywords</b>
                  <TextareaAutosize
                    style={{
                      width: '100%',
                      border: '1px solid black',
                      padding: '5px'
                    }}
                    value={keywords}
                    onChange={e => {
                      setKeywords(e.target.value);
                    }}
                    placeholder="Odvojite zarezom: jakov, primer, kljucne, reci"
                    minRows={1}
                  />{' '}
                </>
              ) : null}
              <b>Title</b>
              <br />
              <div className="area_title">
                <div
                  style={{
                    width: '100%'
                  }}
                >
                  <TextareaAutosize
                    style={{
                      width: '100%',
                      border: '1px solid black',
                      padding: '5px'
                    }}
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                    placeholder="Title"
                    minRows={5}
                  />{' '}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      width: '100%',
                      gap: '10px'
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        option !== 'static_pages'
                          ? title?.replace(/%(\w+)%/g, (match, p1) => {
                              return `<span class="highlight">${p1}</span>`;
                            }) || ''
                          : ''
                    }}
                  />{' '}
                </div>
                <div className="variables">
                  {option !== 'static_pages' ? (
                    <Variables
                      addVariable={e => {
                        setTitle(title + ` %${e}% `);
                      }}
                      type={option}
                    />
                  ) : null}
                </div>
              </div>
            </>
            {option !== 'static_pages' ? (
              <>
                <hr style={{ margin: '20px 0' }} />
                <>
                  {' '}
                  <b>Keywords</b>
                  <br />
                  <div className="area_title">
                    <div
                      style={{
                        width: '100%'
                      }}
                    >
                      <TextareaAutosize
                        style={{
                          width: '100%',
                          border: '1px solid black',
                          padding: '5px'
                        }}
                        value={keywords}
                        onChange={e => {
                          setKeywords(e.target.value);
                        }}
                        placeholder="jakov sistem, laptop, akcija, popust, itd. [odvojite zarezom]"
                        minRows={5}
                      />{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          width: '100%',
                          gap: '10px'
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            option !== 'static_pages'
                              ? keywords?.replace(/%(\w+)%/g, (match, p1) => {
                                  return `<span class="highlight">${p1}</span>`;
                                }) || ''
                              : ''
                        }}
                      />{' '}
                    </div>
                    <div className="variables">
                      {option !== 'static_pages' ? (
                        <Variables
                          addVariable={e => {
                            setKeywords(keywords + ` %${e}% `);
                          }}
                          separator={false}
                          type={option}
                        />
                      ) : null}
                    </div>
                  </div>
                </>
              </>
            ) : null}
            <hr style={{ margin: '20px 0' }} />
            <>
              <b>Description</b>
              <br />
              <div className="area_title">
                <div
                  style={{
                    width: '100%'
                  }}
                >
                  <TextareaAutosize
                    style={{
                      width: '100%',
                      border: '1px solid black',
                      padding: '5px'
                    }}
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                    placeholder="Description"
                    minRows={5}
                  />{' '}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      width: '100%',
                      gap: '10px'
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        option !== 'static_pages'
                          ? description?.replace(/%(\w+)%/g, (match, p1) => {
                              return `<span class="highlight">${p1}</span>`;
                            }) || ''
                          : ''
                    }}
                  />{' '}
                </div>
                <div className="variables">
                  {option !== 'static_pages' ? (
                    <Variables
                      addVariable={e => {
                        setDescription(description + ` %${e}% `);
                      }}
                      type={option}
                    />
                  ) : null}
                </div>
              </div>
            </>
            <hr style={{ margin: '20px 0' }} />
            <FormControl fullWidth>
              <b>Meta robots</b>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={meta_robot}
                defaultValue={'index, follow'}
                onChange={e => setMetaRobot(e.target.value)}
              >
                <MenuItem value={'index, follow'}>Index, follow</MenuItem>
                <MenuItem value={'noindex, follow'}>No index, follow</MenuItem>
                <MenuItem value={'noindex, nofollow'}>
                  No index, no follow
                </MenuItem>
                <MenuItem value={'index, nofollow'}>Index, no follow</MenuItem>
              </Select>
            </FormControl>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                marginTop: '20px'
              }}
            >
              <Button
                type="submit"
                style={{
                  backgroundColor: '#d3351c',
                  color: 'white',
                  marginRight: '10px',
                  width: '100px'
                }}
                onClick={() => {
                  option !== 'static_pages'
                    ? updateSEO({
                        choice: option,
                        title: title.split('%'),
                        description: description.split('%'),
                        meta_robots: meta_robot,
                        keywords: keywords.split('%')
                      })
                    : updateSEO_Static_Pages({
                        title: title,
                        description: description,
                        url: url,
                        keywords: keywords,
                        meta_robots: meta_robot
                      });
                  setSelectedStaticPage({});
                  setURL('');
                  setKeywords('');
                  setTitle('');
                  setDescription('');
                  setMetaRobot('index, follow');
                  dispatch(_getStaticPagesSEO());
                }}
              >
                Izmeni
              </Button>
            </div>
          </>
        </div>
      </div>
      <style>
        {`
          .highlight {
            background-color: #0787ea; /* Highlight color */
            color:white;
            border-radius: 10vw;
            padding: 5px 20px;
            font-weight: bold; /* Optional: make it bold */
          }
        `}
      </style>
    </Card>
  );
};

export default SEO;
