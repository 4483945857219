import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSelects = state => state.selects || initialState;

const makeSelectBrands = () =>
  createSelector(selectSelects, substate => substate.brands);

const makeSelectManufacturers = () =>
  createSelector(selectSelects, substate => substate.manufacturers);

const makeSelectSuppliers = () =>
  createSelector(selectSelects, substate => substate.suppliers);

const makeSelectCategories = () =>
  createSelector(selectSelects, substate => substate.categories);

const makeSelectDiscountsTypes = () =>
  createSelector(selectSelects, substate => substate.discountsTypes);
const makeSelectStickerForProducts = () =>
  createSelector(selectSelects, substate => substate.stickerForProducts);

const makeSelectAttributes = () =>
  createSelector(selectSelects, substate => substate.attributes);

const makeSelectPromoCodes = () =>
  createSelector(selectSelects, substate => substate.promocodes);

export {
  makeSelectBrands,
  makeSelectSuppliers,
  makeSelectCategories,
  makeSelectDiscountsTypes,
  makeSelectAttributes,
  makeSelectManufacturers,
  makeSelectStickerForProducts,
  makeSelectPromoCodes
};
