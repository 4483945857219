const KEY = '[CMS_RATINGS]';

const types = {
  GET_RATINGS: `${KEY} GET_RATINGS`,
  SET_RATINGS: `${KEY} SET_RATINGS`,

  GET_RATING: `${KEY} GET_RATING`,
  SET_RATING: `${KEY} SET_RATING`,

  GET_PARAMS: `${KEY} GET_PARAMS`,
  SET_PARAMS: `${KEY} SET_PARAMS`,

  APPROVE_RATING: `${KEY} APPROVE_RATING`,
  DELETE_RATINGS: `${KEY} DELETE_RATINGS`,

  SEND_PROMO: `${KEY} SEND_PROMO`
};

export default types;
