const KEY = '[CMS_LANDING_PAGE]';

const types = {
  UPDATE_SEO: `${KEY} UPDATE_SEO`,
  SET_STATIC_PAGES: `${KEY} SET_STATIC_PAGES`,
  GET_STATIC_PAGES: `${KEY} GET_STATIC_PAGES`,
  UPDATE_SEO_STATIC_PAGES: `${KEY} UPDATE_SEO_STATIC_PAGES`
};

export default types;
