import types from './constants';

export const getProducts = payload => ({
  type: types.GET_PRODUCTS,
  payload
});

export const setProducts = payload => ({
  type: types.SET_PRODUCTS,
  payload
});

export const setAllIds = payload => ({
  type: types.SET_ALL_IDS,
  payload
});

export const setParams = payload => ({
  type: types.SET_PARAMS,
  payload
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});

export const updateDescription = (params, payload, close, clear) => ({
  type: types.UPDATE_DESCRIPTIONS,
  params,
  payload,
  close,
  clear
});

export const updateDescriptionSuccess = () => ({
  type: types.UPDATE_DESCRIPTIONS_SUCCESS
});

export const massActivateAttributes = (payload, params) => ({
  type: types.MASS_ACTIVATE_ATTRIBUTES_REQUEST,
  payload,
  params
});

export const massActivateAttributesSuccess = () => ({
  type: types.MASS_ACTIVATE_ATTRIBUTES_SUCCESS
});

export const massDeactivateAttributes = payload => ({
  type: types.MASS_DEACTIVATE_ATTRIBUTES_REQUEST,
  payload
});

export const massDeactivateAttributesSuccess = () => ({
  type: types.MASS_DEACTIVATE_ATTRIBUTES_SUCCESS
});

export const disconnectProduct = (id, close) => ({
  type: types.DISCONNECT_PRODUCT,
  id,
  close
});

export const disconnectProductSuccess = () => ({
  type: types.DISCONNECT_PRODUCT_SUCCESS
});

export const massUpdateDiscounts = (
  payload,
  params,
  close,
  clear,
  setErrors
) => ({
  type: types.MASS_UPDATE_DISCOUNTS,
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
});

export const uploadSticker = payload => ({
  type: types.UPLOAD_STICKER,
  payload
});

export const uploadAction = payload => ({
  type: types.UPLOAD_ACTION,
  payload
});

export const addPromoCode = (payload, params, close, clear, setErrors) => ({
  type: types.ADD_PROMO_CODE,
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
});
