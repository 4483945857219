/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import useUserKeyPress from 'helpers/useUserKeyPress';
import messages from './messages';

let focused = false;

const Search = ({
  params,
  setParams,
  isMain,
  setInclude,
  disableSearchFocus
}) => {
  const [delay, setDelay] = useState('');
  const inputRef = useRef(null);
  const { formatMessage } = useIntl();

  const [dftValue, setDftValue] = useState(null);

  useUserKeyPress(27, e => {
    e.preventDefault();
    inputRef.current.blur();
  });

  useUserKeyPress(191, e => {
    if (!disableSearchFocus && !focused) {
      e.preventDefault();
      inputRef.current.focus();
      focused = true;
    }
  });

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    if (!text.length) {
      text = ' ';
    }
    copyState = setTimeout(() => {
      if (!text.length) {
        const { search: tmp, ...rest } = params;
        setParams({ ...rest, page: 1 });
        if (isMain) setInclude('?include=all_subcategories&main=1');
      } else {
        if (isMain) {
          setInclude('');
          const { main: rmv, ...rest2 } = params;
          setParams({ ...rest2, search: text, page: 1 });
        } else {
          setParams({ ...params, search: text, page: 1 });
        }
      }
    }, 500);
    setDelay(copyState);
  };

  return (
    <div className="search_holder">
      <FiSearch />
      <input
        type="search"
        placeholder={formatMessage(
          disableSearchFocus ? messages.search : messages.search_placeholder
        )}
        defaultValue={dftValue}
        onChange={handleChange}
        ref={inputRef}
        onFocus={() => (focused = true)}
        onBlur={() => (focused = false)}
      />
    </div>
  );
};

export default Search;
