const variables = {
  email: 'info@jakovsistem.com',
  facebook_link: 'https://www.facebook.com/JakovSistemOnlineShop',
  instagram_link: 'https://www.instagram.com/jakovsistem',
  skype_link: 'skype:prodajajakovsistem?chat',
  phone1: '011 630 50 33',
  phone2: '018 41 55 222',
  phone3: '066 8 220 220'
};

export default variables;
