import produce from 'immer';
import types from './constants';

export const initialState = {
  static_pages: []
};

/* eslint-disable default-case */
const cmsBannersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.UPDATE_SEO:
        break;
      case types.UPDATE_SEO_STATIC_PAGES:
        break;
      case types.SET_STATIC_PAGES:
        draft.static_pages = action.payload;
        break;
    }
  });

export default cmsBannersReducer;
