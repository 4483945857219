import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';
import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'cms/components/Selects/redux/reducer';
import saga from 'cms/components/Selects/redux/saga';
import { fetchCategories } from 'cms/components/Selects/redux/actions';
import { makeSelectCategories } from 'cms/components/Selects/redux/selectors';
import messages from '../messages';
import useUserKeyDown from 'helpers/useUserKeyDown';

const key = 'selects';

const CategoriesSelect = ({
  params,
  setParams,
  isVisible,
  setIsVisible,
  satValue
}) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [delay, setDelay] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const results = useSelector(makeSelectCategories());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isVisible && params && results.length) {
      let activeItem = results.map(item => item.name).indexOf(params.category);
      if (activeItem > -1) setActiveIndex(activeItem);
      else setActiveIndex(0);
    }
  }, [isVisible, results, params]);

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(results.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex < results.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, results], () => {
    if (isVisible && results.length) handleClick(results[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) dispatch(fetchCategories(``));
  }, [isVisible, dispatch]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(fetchCategories(`${text}`));
    }, 500);
    setDelay(copyState);
    setActiveIndex(0);
  };

  const handleClick = item => {
    setParams({ ...params, category: item.name });
    satValue(item.id);
    toggle();
  };

  // const handleClick = item => {
  //   // const prev = params.category ? [...params.category] : []
  //   // if(!prev.find(p=> p.name === item.name)){
  //   //   setParams({ ...params, category: [...prev,{name:item.name,id:item.id}] });
  //   //   // toggle();
  //   // }
  //   let prev = null;
  //   if (typeof params.categories === 'string') {
  //     let data = [];
  //     const categories = params.categories.split(',');
  //     categories.forEach(man => {
  //       const item = results.find(res => String(res.id) === man);
  //       if (item) data.push(item);
  //     });
  //     prev = [...data];
  //   } else {
  //     prev = params.categories ? [...params.categories] : [];
  //   }
  //   if (!prev.find(p => p.value === item.name)) {
  //     setParams({
  //       ...params,
  //       categories: [...prev, { value: item.name, id: item.id }]
  //     });
  //   }
  // };

  // const handleDelete = () => {
  //   const { category: tmp, ...rest } = params;
  //   setParams(rest);
  //   satValue(null);
  //   toggle();
  // };

  const handleDelete = category => () => {
    const rest = params.category.filter(m => m.name !== category.value);
    setParams({ ...params, category: rest });
  };
  // const handleDelete = category => () => {
  //   const rest = params.categories.filter(m => m.value !== category.value);
  //   setParams({ ...params, categories: rest });
  // };

  // const getCategories = () => {
  //   const mans = params.categories.split(',');
  //   let data = [];
  //   mans.forEach(m => {
  //     const el = results.find(res => String(res.id) === m);
  //     if (el) data.push({ ...el, value: el.name });
  //   });
  //   return data;
  // };
  return (
    <div className="group">
      <div className={activeClass('search_select_wrapper', isVisible)}>
        <div className="search_select_holder">
          <div className="root" onClick={toggle}>
            <p>
              {params.category ? (
                <span>
                  {params.category}
                  <FaTimes onClick={handleDelete} />
                </span>
              ) : (
                formatMessage(messages.choose_category)
              )}
            </p>
            {/* <p>
              {params.categories && params.categories.length
                ? typeof params.categories !== 'string'
                  ? params.categories.map((category, i) => {
                      return (
                        <span key={i}>
                          {category.value}
                          <FaTimes onClick={handleDelete(category)} />
                        </span>
                      );
                    })
                  : getCategories().map((category, i) => {
                      return (
                        <span key={i}>
                          {category.value}
                          <FaTimes onClick={handleDelete(category)} />
                        </span>
                      );
                    })
                : formatMessage(messages.choose_category)}
            </p> */}

            <span className="icon">
              <FaSortDown />
            </span>
          </div>
          <label>{formatMessage(messages.all_cateogires)}</label>
          {isVisible && (
            <div className="dropdown_holder" ref={modal}>
              <div className="dropdown_content">
                <input
                  type="text"
                  placeholder={formatMessage(messages.search_placeholder)}
                  onChange={handleChange}
                  autoFocus
                />
                {results.length ? (
                  <ul>
                    {results.map((item, index) => (
                      <li
                        key={item.id}
                        onClick={() => handleClick(item)}
                        onMouseEnter={() => setActiveIndex(index)}
                        className={activeClass('', activeIndex === index)}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="no_result">
                    {formatMessage(messages.no_result)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoriesSelect;
