import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { recommendedPriceSchema } from '../validations';
import SuppliersSelect from 'cms/components/Selects/Suppliers';
import CategorySelect from 'cms/components/Selects/Category';
import FormikField from 'components/FormikField';
import Buttons from 'cms/components/Formik/Buttons';
import routes from 'routes';
import messages from '../../messages';

import Select from 'react-select';

import reducer from '../../redux/reducer';
import saga from '../../redux/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectBrands,
  makeSelectCategories
} from 'cms/JakovLager/redux/selectors';
import { getBrands, getCategories } from 'cms/JakovLager/redux/actions';

const key = 'lager';

export default function Update({
  id,
  data,
  onSubmit,
  isPending,
  exclusion = false
}) {
  const { formatMessage } = useIntl();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const categories = useSelector(makeSelectCategories());
  const brandsList = useSelector(makeSelectBrands());

  const [cats, setCats] = React.useState(
    data?.categories?.map(c => {
      return { value: c?.id, label: c?.name };
    }) || []
  );
  const [brands, setBrands] = React.useState(
    data?.brands?.map(c => {
      return { value: c?.id, label: c?.name };
    }) || []
  );

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
  }, [dispatch]);

  console.log('DATA', cats);

  useEffect(() => {
    setCats(
      data?.categories?.map(c => {
        return { value: c?.id, label: c?.name };
      }) || []
    );
    setBrands(
      data?.brands?.map(c => {
        return { value: c?.id, label: c?.name };
      }) || []
    );
  }, [data]);

  const handleSubmit = (values, { setErrors }) => {
    const { since_the_day, until_the_day, margin } = values;
    let payload = {
      since_the_day,
      until_the_day,
      categories: cats.map(cat => cat.value)?.join(', '),
      brands: brands.map(brand => brand.value)?.join(', '),
      margin
    };
    onSubmit(id, payload, setErrors);
  };

  return (
    <Formik
      initialValues={{
        since_the_day: data?.since_the_day || '',
        until_the_day: data?.until_the_day || '',
        categories: [],
        brands: [],
        margin: data?.margin || ''
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={recommendedPriceSchema}
      onSubmit={(values, { setErrors }) => handleSubmit(values, { setErrors })}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="form_holder">
          <div className="form_group">
            {categories && brands ? (
              <>
                <div style={{ zIndex: 999 }}>
                  <Select
                    name="categories"
                    placeholder={'Izaberi kategorije'}
                    isMulti
                    value={cats}
                    options={
                      categories
                        ? Object?.values(categories)?.map(category => {
                            return { value: category.id, label: category.name };
                          })
                        : []
                    }
                    onChange={option => {
                      setCats(option);
                    }}
                  />
                </div>
                <div style={{ zIndex: 999 }}>
                  <Select
                    name="brands"
                    placeholder={'Izaberi brendove'}
                    isMulti
                    value={brands}
                    options={
                      brandsList
                        ? Object?.values(brandsList)?.map(category => {
                            return { value: category.id, label: category.name };
                          })
                        : []
                    }
                    onChange={option => {
                      setBrands(option);
                    }}
                  />
                </div>
              </>
            ) : null}
            {errors.category && touched.category ? (
              <div className="error">{errors.category}</div>
            ) : null}
            <FormikField
              type="number"
              name="since_the_day"
              label={{
                id: `the name`,
                defaultMessage: 'Od dana'
              }}
              required
              errors={errors}
              touched={touched}
            />{' '}
            <FormikField
              type="number"
              name="until_the_day"
              label={{
                id: `the name`,
                defaultMessage: 'Do dana'
              }}
              errors={errors}
              touched={touched}
            />{' '}
            {!exclusion ? (
              <FormikField
                type="number"
                name="margin"
                label={{
                  id: `the name`,
                  defaultMessage: 'Marža'
                }}
                errors={errors}
                touched={touched}
              />
            ) : null}
          </div>
          <Buttons
            link={routes.CMS_RECOMMENDED_PRICE}
            label={messages.edit}
            isPending={
              !values.since_the_day ||
              !values.until_the_day ||
              (!exclusion && !values.margin)
            }
          />
        </Form>
      )}
    </Formik>
  );
}

Update.propTypes = {
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool
};
