import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import types from './constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { _getStaticPagesSEO, _setStaticPagesSEO } from './actions';

export function* updateSEO({ params }) {
  try {
    const res = yield call(request, {
      url: 'admin/seo',
      method: 'post',
      data: params
    });
    console.log('res', res);
    //yield put(setCatalog(res?.data));
    yield put(
      enqueueSnackbar({
        message: 'SEO uspešno ažuriran',
        options: { variant: 'success' }
      })
    );
  } catch (error) {}
}

// eslint-disable-next-line require-yield
export function* updateSEOStatic_Pages({ params }) {
  try {
    console.log('seo update action', params);
    let data = new FormData();
    data.append('choice', 'static_pages');
    params.forEach((param, index) => {
      data.append(`static_pages[${index}][url]`, param.url);
      data.append(`static_pages[${index}][title]`, param.title);
      data.append(`static_pages[${index}][description]`, param.description);
      data.append(`static_pages[${index}][keywords]`, param.keywords);
      data.append(`static_pages[${index}][meta_robots]`, param.meta_robots);
    });
    console.log('data', data);
    const res = yield call(request, {
      url: 'admin/seo',
      method: 'post',
      data: data
    });
    console.log('res', res);
    yield put(_setStaticPagesSEO(res?.data));
    yield put(
      enqueueSnackbar({
        message: 'SEO uspešno ažuriran',
        options: { variant: 'success' }
      })
    );
  } catch (error) {
    console.log('error', error);
  }
}

export function* getStaticPagesSEO() {
  try {
    const res = yield call(request, {
      url: 'seo-static-pages',
      method: 'get'
    });
    console.log('res', res);

    yield put(_setStaticPagesSEO(res.data));
  } catch (error) {
    return null;
  }
}

export default function* cmsSEO() {
  yield takeLatest(types.UPDATE_SEO, updateSEO);
  yield takeLatest(types.UPDATE_SEO_STATIC_PAGES, updateSEOStatic_Pages);
  yield takeLatest(types.GET_STATIC_PAGES, getStaticPagesSEO);
}
