import React, { useState } from 'react';

import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import Checkbox from 'cms/components/Checkbox';
import { priceToFixed } from 'helpers/priceToFixed';
import Filters from './Filters';
import { Button, IconButton } from '@material-ui/core';
import { ReactComponent as FiltersIcon } from 'assets/images/icons/filters.svg';
import styled from 'styled-components';
import Axios from 'axios';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { useDispatch } from 'react-redux';
import PreviewFilters from './Filters/Preview';

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Table = ({ ok, nok, handleSetFilters, state }) => {
  const [checkedItemsOk, setCheckedItemsOk] = useState([]);
  const [checkAllOk, setCheckAllOk] = useState(false);
  const [checkedItemsNok, setCheckedItemsNok] = useState([]);
  const [checkAllNok, setCheckAllNok] = useState(false);
  const [isVisibleOk, setIsVisibleOk] = useState(false);
  const [okFilters, setOkFilters] = useState(null);
  const [categoriesSelectVisibleOk, setCategoriesSelectVisibleOk] = useState(
    false
  );
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [selectCategoryIdOk, setSelectCategoryIdOk] = useState(null);
  const toggleFiltersMenuOk = () => setIsVisibleOk(!isVisibleOk);

  const handleClickOk = id => {
    if (checkedItemsOk.includes(id)) {
      setCheckAllOk(false);
      setCheckedItemsOk(checkedItemsOk.filter(item => item !== id));
    } else {
      setCheckedItemsOk([...checkedItemsOk, id]);
    }
  };
  const handleCheckAllOk = () => {
    setCheckAllOk(!checkAllOk);
    if (!checkAllOk) {
      let tmpArr = [];
      ok.forEach(item => {
        tmpArr.push(item.id);
      });
      setCheckedItemsOk(tmpArr);
    }
    if (checkAllOk) setCheckedItemsOk([]);
  };

  const handleClickNok = id => {
    if (checkedItemsNok.includes(id)) {
      setCheckAllNok(false);
      setCheckedItemsNok(checkedItemsNok.filter(item => item !== id));
    } else {
      setCheckedItemsNok([...checkedItemsNok, id]);
    }
  };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.token.split('"').join('')}`
    }
  };
  const handleCheckAllNok = () => {
    setCheckAllNok(!checkAllNok);
    if (!checkAllNok) {
      let tmpArr = [];
      nok.forEach(item => {
        tmpArr.push(item.id);
      });
      setCheckedItemsNok(tmpArr);
    }
    if (checkAllNok) setCheckedItemsNok([]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('relative_to', state.relative_to);
    formData.append('format', state.format);
    formData.append('value', state.value);
    formData.append('irrelevant[]', state.irrelevant);
    [...checkedItemsOk, ...checkedItemsNok].forEach((item, i) => {
      formData.append(`ids[${i}]`, item);
    });
    const response = await Axios.post(
      `https://api.jakov.rs/api/admin/eponuda-update`,
      formData,
      config
    );
    if (response?.data?.message)
      dispatch(enqueueSnackbar({ message: response.data.message }));
  };

  return (
    <div className="cms_product_attributes_table" id="oknok-table">
      <div
        className="flex justify-end"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '20px 10px'
        }}
      >
        <IconButton
          className="icon_button filter_icon"
          onClick={toggleFiltersMenuOk}
        >
          <FiltersIcon />
        </IconButton>
      </div>
      {isVisibleOk && (
        <>
          <Filters
            close={toggleFiltersMenuOk}
            setParams={data => {
              setOkFilters(data);
              handleSetFilters(data);
            }}
            params={okFilters}
            categoriesSelectVisible={categoriesSelectVisibleOk}
            setCategoriesSelectVisible={setCategoriesSelectVisibleOk}
            setSelectCategoryId={setSelectCategoryIdOk}
          />
        </>
      )}
      {!okFilters ? null : (
        <PreviewFilters
          params={okFilters}
          setParams={data => {
            setOkFilters(data);
            handleSetFilters(data);
          }}
        />
      )}
      <h3>U okviru ulazne cene</h3>
      <TableHelper
        tableName="ok-nok"
        tableHeaders={[
          'Ime',
          'SKU',
          'UC sa PDV', // 5
          'Web cena',
          'Min treća cena na EP',
          'Naša nova cena',
          'Pozicija',
          //   'Web cena', // 5
          //   'Ime konkurenta 1', //8
          //   'Cena konkurenta 1', // 5
          //   'Ime konkurenta 2', //8
          //   'Cena konkurenta 2', // 5
          //   'Ime konkurenta 3', //8
          //   'Cena konkurenta 3', // 5
          //   'Ime konkurenta 4', //8
          //   'Cena konkurenta 4', // 5
          //   'Ime konkurenta 5', //8
          //   'Cena konkurenta 5', // 5
          'Ime kategorije', // 8
          'Ime dobavljača' //8
        ]}
        checkboxValue={checkAllOk}
        setCheckboxValue={handleCheckAllOk}
      >
        {ok.length ? (
          ok.map(item => (
            <TableRow key={item.id}>
              <TableCell>
                <Checkbox
                  checked={
                    checkAllOk ? checkAllOk : checkedItemsOk.includes(item.id)
                  }
                  onClick={() => handleClickOk(item.id)}
                />
              </TableCell>
              <TableCell classes="p15-0">{item.name}</TableCell>
                 <TableCell classes="p15-0">{item.sku}</TableCell>
                 <TableCell classes="p15-0">
                {priceToFixed(item.entry_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.web_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {item.eponuda_min}
              </TableCell>
              <TableCell classes="p15-0">
                  {item.created_price}
              </TableCell>
              <TableCell classes="p15-0">{item.position}</TableCell>
              {/* <TableCell classes="p15-0">
                {priceToFixed(item.entry_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.web_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.eponuda_min)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.created_price)}
              </TableCell>
              <TableCell classes="p15-0">{item.position}</TableCell> */}
              {/* <TableCell classes="p15-0">{item.competitor_name_1}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_1)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_2}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_2)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_3}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_3)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_4}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_4)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_5}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_5)}
                </TableCell> */}
              <TableCell classes="p15-0">{item.category[0].name}</TableCell>
              {(() => {
                if (item.supplier) {
                  return (
                    <TableCell classes="p15-0">{item.supplier.name}</TableCell>
                  )
                } else {
                  return (
                    <TableCell classes="p15-0"></TableCell>
                  )
                }
              })()}              
            </TableRow>
          ))
        ) : (
          <p className="m-5">Nema podataka</p>
        )}
      </TableHelper>
      <br />
      <h3>Ispod ulazne cene</h3>

      <TableHelper
        tableName="ok-nok"
        tableHeaders={[
          'Ime',
          'SKU',
          'UC sa PDV', // 5
          'Web cena',
          'Min treća cena na EP',
          'Naša nova cena',
          'Pozicija',
          //   'Web cena', // 5
          //   'Ime konkurenta 1', //8
          //   'Cena konkurenta 1', // 5
          //   'Ime konkurenta 2', //8
          //   'Cena konkurenta 2', // 5
          //   'Ime konkurenta 3', //8
          //   'Cena konkurenta 3', // 5
          //   'Ime konkurenta 4', //8
          //   'Cena konkurenta 4', // 5
          //   'Ime konkurenta 5', //8
          //   'Cena konkurenta 5', // 5
          'Ime kategorije', // 8
          'Ime dobavljača' //8
        ]}
        checkboxValue={checkAllNok}
        setCheckboxValue={handleCheckAllNok}
      >
        {nok.length ? (
          nok.map(item => (
            <TableRow key={item.id}>
              <TableCell>
                <Checkbox
                  checked={
                    checkAllNok
                      ? checkAllNok
                      : checkedItemsNok.includes(item.id)
                  }
                  onClick={() => handleClickNok(item.id)}
                />
              </TableCell>
              <TableCell classes="p15-0">{item.name}</TableCell>
                 <TableCell classes="p15-0">{item.sku}</TableCell>
                 <TableCell classes="p15-0">
                {priceToFixed(item.entry_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.web_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {item.eponuda_min}
              </TableCell>
              <TableCell classes="p15-0">
                  {item.created_price}
              </TableCell>
              <TableCell classes="p15-0">{item.position}</TableCell>
              {/* <TableCell classes="p15-0">{item.sku}</TableCell>
              <TableCell classes="p15-0">
                {priceToFixed(item.entry_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.web_price)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.eponuda_min)}
              </TableCell>
              <TableCell classes="p15-0">
                  {priceToFixed(item.created_price)}
              </TableCell>
              <TableCell classes="p15-0">{item.position}</TableCell> */}
              {/*                <TableCell classes="p15-0">{item.competitor_name_1}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_1)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_2}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_2)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_3}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_3)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_4}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_4)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_5}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_5)}
                </TableCell> */}
              <TableCell classes="p15-0">{item.category[0].name}</TableCell>
              {(() => {
                if (item.supplier) {
                  return (
                    <TableCell classes="p15-0">{item.supplier.name}</TableCell>
                  )
                } else {
                  return (
                    <TableCell classes="p15-0"></TableCell>
                  )
                }
              })()}   
            </TableRow>
          ))
        ) : (
          <p className="m-5">Nema podataka</p>
        )}
      </TableHelper>
      <FlexContainer>
        <Button
          color="primary"
          variant={'contained'}
          onClick={handleSubmit}
          style={{ marginTop: '15px' }}
          disabled={![...checkedItemsOk, ...checkedItemsNok].length}
        >
          Potvrdi
        </Button>
      </FlexContainer>
    </div>
  );
};

export default Table;
