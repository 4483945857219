const KEY = '[CMS_LINKING_PRODUCTS]';

const types = {
  GET_OUR_PRODUCTS: `${KEY} GET_OUR_PRODUCTS`,
  SET_OUR_PRODUCTS: `${KEY} SET_OUR_PRODUCTS`,

  OUR_PRODUCTS_SET_PARAMS: `${KEY} OUR_PRODUCTS_SET_PARAMS`,
  OUR_PRODUCTS_DELETE_PARAMS: `${KEY} OUR_PRODUCTS_DELETE_PARAMS`,

  GET_GROUPED_PRODUCTS: `${KEY} GET_GROUPED_PRODUCTS`,
  SET_GROUPED_PRODUCTS: `${KEY} SET_GROUPED_PRODUCTS`,

  SET_PARAMS_GROUPED_PRODUCTS: `${KEY} SET_PARAMS_GROUPED_PRODUCTS`,
  DELETE_PARAMS_GROUPED_PRODUCTS: `${KEY} DELETE_PARAMS_GROUPED_PRODUCTS`,

  SET_GROUPED_PRODUCTS_LOADER: `${KEY} SET_GROUPED_PRODUCTS_LOADER`,

  GET_PRICELIST_PRODUCT: `${KEY} GET_PRICELIST_PRODUCT`,
  SET_PRICELIST_PRODUCT: `${KEY} SET_PRICELIST_PRODUCT`,

  CONNECT_PRODUCT: `${KEY} CONNECT_PRODUCT`,
  CONNECT_PRODUCT_SUCCESS: `${KEY} CONNECT_PRODUCT_SUCCESS`,

  DISCONNECT_PRODUCT: `${KEY} DISCONNECT_PRODUCT`,
  DISCONNECT_PRODUCT_SUCCESS: `${KEY} DISCONNECT_PRODUCT_SUCCESS`,

  // Create product
  CREATE_PRODUCT_REQUEST: `${KEY} CREATE_PRODUCT_REQUEST`,

  CREATE_MAIN_PHOTO: `${KEY} CREATE_MAIN_PHOTO`,
  SEND_PHOTOS_REQUEST: `${KEY} SEND_PHOTOS_REQUEST`,

  CREATE_BRAND: `${KEY} CREATE_BRAND`,
  CREATE_BRAND_SUCCESS: `${KEY} CREATE_BRAND_SUCCESS`,
  CREATE_BRAND_ERROR: `${KEY} CREATE_BRAND_ERROR`,

  GET_SUPPLIER: `${KEY} GET_SUPPLIER`,
  SET_SUPPLIER: `${KEY} SET_SUPPLIER`
};

export default types;
