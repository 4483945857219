import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRecommendedPrices = state => state.lager || initialState;

const makeSelectCategories = () =>
  createSelector(selectRecommendedPrices, substate => substate.categories);

const makeSelectBrands = () =>
  createSelector(selectRecommendedPrices, substate => substate.brands);

const makeSelectConditions = () =>
  createSelector(selectRecommendedPrices, substate => substate.conditions);

const makeSelectCondition = () =>
  createSelector(selectRecommendedPrices, substate => substate.condition);

const makeSelectExclusions = () =>
  createSelector(selectRecommendedPrices, substate => substate.exclusions);

const makeSelectExclusion = () =>
  createSelector(selectRecommendedPrices, substate => substate.exclusion);

export {
  makeSelectCategories,
  makeSelectBrands,
  makeSelectConditions,
  makeSelectCondition,
  makeSelectExclusions,
  makeSelectExclusion
};
