/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { HiPencil } from 'react-icons/hi';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card } from '@material-ui/core';
import InputLink from './editor/InputLink';
import reducer from '../redux/reducer';
import saga from '../redux/saga';
import Preview from './editor/Preview';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectCatalogs,
  makeSelectProducts,
  makeSelectSuccess
} from '../redux/selectors';
import { useEffect } from 'react';
import {
  getCatalogs,
  pickCatalog,
  setSuccess,
  submitLandingPage,
  updateLandingPage
} from '../redux/actions';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

const key = 'landing_page';

export default function FirstEditor({ newBool, page }) {
  const [image, setImage] = useState(null);
  const [tab_image, setTab_Image] = useState(null);
  const [chosenCatalog, setChosenCatalog] = useState(null);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const catalogs = useSelector(makeSelectCatalogs());
  const products = useSelector(makeSelectProducts());
  const success = useSelector(makeSelectSuccess());

  const [tmpProducts, setTmpProducts] = useState([]);

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(setSuccess(false));
  }, []);

  useEffect(() => {
    if (!page) {
      dispatch(pickCatalog(chosenCatalog?.slug));
      setImage('https://api.jakov.rs/storage/' + chosenCatalog?.desktop_image);
      setTab_Image('https://api.jakov.rs/storage/' + chosenCatalog?.sticker);
    }
  }, [chosenCatalog]);

  useEffect(() => {
    setTmpProducts(products);
  }, [products]);

  useEffect(() => {
    if (page) {
      setTmpProducts(page?.products);
      setImage(
        'https://api.jakov.rs/storage/' +
          page?.catalog_sticker_type?.desktop_image
      );
      setTab_Image(page?.card2);
      setChosenCatalog(page?.catalog_sticker_type);
    }
  }, [page]);

  useEffect(() => {
    if (success) {
      newBool(false);
    }
  }, [success]);

  return (
    <div className="form_first">
      <Formik
        initialValues={
          page && {
            name: page?.name,
            top_description:
              page?.top_description &&
              EditorState.createWithContent(
                convertFromRaw(JSON.parse(page.top_description))
              ),
            bottom_description:
              page?.bottom_description &&
              EditorState.createWithContent(
                convertFromRaw(JSON.parse(page.bottom_description))
              ),
            card1:
              page?.card1 &&
              EditorState.createWithContent(
                convertFromRaw(JSON.parse(page.card1))
              )
          }
        }
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          try {
            const formData = new FormData();

            formData.append('name', values?.name);
            //image && formData.append('banner', image);
            formData.append('landing_page_model_id', 1);
            formData.append('catalog_sticker_type_id', chosenCatalog?.id);
            values?.top_description &&
              formData.append(
                'top_description',
                JSON.stringify(
                  convertToRaw(values.top_description.getCurrentContent())
                )
              );
            values?.bottom_description &&
              formData.append(
                'bottom_description',
                JSON.stringify(
                  convertToRaw(values.bottom_description.getCurrentContent())
                )
              );

            tmpProducts
              .map(obj => obj?.id)
              .forEach((id, index) => {
                formData.append(`products_ids[${index}]`, id);
              });

            values?.card1 &&
              formData.append(
                'card1',
                JSON.stringify(convertToRaw(values.card1.getCurrentContent()))
              );

            tab_image && formData.append('card2', tab_image);

            dispatch(
              !page
                ? submitLandingPage(formData)
                : updateLandingPage(page?.id, formData)
            );
          } catch {}
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {chosenCatalog !== null || page ? (
              <>
                <p>
                  <snap>Početna strana </snap>/{' '}
                  <input
                    type="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <HiPencil color="#0787ea" />
                </p>
                <div className="content">
                  <div className="header">
                    <div className="dropzone">
                      {image && <img src={image} alt="header_img" />}
                    </div>
                    <div className="editor_header">
                      <Editor
                        editorState={values.top_description}
                        onEditorStateChange={val =>
                          setFieldValue('top_description', val)
                        }
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        placeholder="Opis"
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'list',
                            'textAlign',
                            'history',
                            'link'
                          ]
                        }}
                      />
                      <Card className="tab">
                        <div className="dropzone_card">
                          {tab_image && <img src={tab_image} alt="tab_image" />}
                        </div>
                        <div className="tab_desc">
                          <Editor
                            editorState={values.card1}
                            onEditorStateChange={val =>
                              setFieldValue('card1', val)
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            placeholder="Opis"
                            toolbar={{
                              options: [
                                'inline',
                                'blockType',
                                'fontSize',
                                'list',
                                'textAlign',
                                'history',
                                'link'
                              ]
                            }}
                          />
                        </div>
                      </Card>
                    </div>
                  </div>

                  <div className="body">
                    <div className="product_preview">
                      <InputLink
                        label={'Pogledaj sve proizvode'}
                        setValue={el => {
                          setFieldValue('see_more_body', el?.target?.value);
                        }}
                      />
                      <Preview
                        data={tmpProducts}
                        edit={page ? true : false}
                        changeData={setTmpProducts}
                      />
                    </div>
                    <Editor
                      editorState={values.bottom_description}
                      onEditorStateChange={val =>
                        setFieldValue('bottom_description', val)
                      }
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Opis"
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'fontSize',
                          'list',
                          'textAlign',
                          'history',
                          'link'
                        ]
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <h1>Izaberi Akciju</h1>
                <div className="choose_catalog">
                  {Object.entries(catalogs).length &&
                    catalogs.map(catalog => {
                      return (
                        <div
                          className="card"
                          onClick={() => setChosenCatalog(catalog)}
                        >
                          <img
                            src={
                              'https://api.jakov.rs/storage/' +
                              catalog?.desktop_image
                            }
                            alt={catalog?.slug}
                          />
                        </div>
                      );
                    })}
                </div>
              </>
            )}

            <button type="submit">Napravi</button>
          </form>
        )}
      </Formik>
    </div>
  );
}
