import types from './constants';

// Get
export const getCategories = () => ({
  type: types.GET_CATEGORIES
});

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload
});

export const getBrands = () => ({
  type: types.GET_BRANDS
});

export const setBrands = payload => ({
  type: types.SET_BRANDS,
  payload
});

// Conditions
export const getConditions = () => ({
  type: types.GET_CONDITIONS
});

export const getCondition = id => ({
  type: types.GET_CONDITION,
  id
});

export const setCondition = payload => ({
  type: types.SET_CONDITION,
  payload
});

export const setConditions = payload => ({
  type: types.SET_CONDITIONS,
  payload
});

export const createCondition = payload => ({
  type: types.CREATE_CONDITION,
  payload
});

export const updateCondition = (id, payload) => ({
  type: types.UPDATE_CONDITION,
  id,
  payload
});

export const deleteCondition = id => ({
  type: types.DELETE_CONDITION,
  id
});

// Exclusions
export const getExclusions = () => ({
  type: types.GET_EXCLUSIONS
});

export const getExclusion = id => ({
  type: types.GET_EXCLUSION,
  id
});

export const setExclusion = payload => ({
  type: types.SET_EXCLUSION,
  payload
});

export const setExclusions = payload => ({
  type: types.SET_EXCLUSIONS,
  payload
});

export const createExclusion = payload => ({
  type: types.CREATE_EXCLUSION,
  payload
});

export const updateExclusion = (id, payload) => ({
  type: types.UPDATE_EXCLUSION,
  id,
  payload
});

export const deleteExclusion = id => ({
  type: types.DELETE_EXCLUSION,
  id
});
