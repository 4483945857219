/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from 'react-daterange-picker';
import { getSearchStats } from './redux/actions';
import { makeSelectSearchStats } from './redux/selectors';
import moment from 'moment';
import 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import reducer from './redux/reducer';
import saga from './redux/saga';
import { BiDownload } from 'react-icons/bi';

const key = 'dashboard';

export default function DashboardPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const searchStats = useSelector(makeSelectSearchStats());
  console.log(searchStats);
  const [dateRange, setDateRange] = useState(
    moment.range(
      moment()
        .subtract(7, 'days')
        .utcOffset('+01:00')
        .startOf('day'),
      moment()
        .utcOffset('+01:00')
        .endOf('day')
    )
  );

  useEffect(() => {
    dispatch(
      getSearchStats({
        from: dateRange.start
          .utcOffset('+01:00')
          .startOf('day')
          .toISOString(),
        to: dateRange.end
          .utcOffset('+01:00')
          .endOf('day')
          .toISOString()
      })
    );
  }, [dispatch, dateRange]);

  return (
    <div className="dashboard-container">
      <div
        style={{
          minHeight: '100px',
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          padding: '20px',
          position: 'relative'
        }}
        className="search_stats_container"
      >
        <div
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px'
          }}
        >
          <a
            href="#"
            onClick={async e => {
              e.preventDefault();
              try {
                const token = localStorage
                  .getItem('token')
                  ?.replaceAll('"', '');
                const response = await fetch(
                  'https://api.jakov.rs/api/v1/admin/search-statistics-csv',
                  {
                    method: 'GET',
                    headers: {
                      Authorization: `Bearer ${token}`
                    },
                    params: {
                      from: dateRange.start
                        .utcOffset('+01:00')
                        .startOf('day')
                        .toISOString(),
                      to: dateRange.end
                        .utcOffset('+01:00')
                        .endOf('day')
                        .toISOString()
                    }
                  }
                );

                if (!response.ok) throw new Error('Download failed');

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `search-statistics-${dateRange.start.format(
                  'YYYY-MM-DD'
                )}-${dateRange.end.format('YYYY-MM-DD')}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              } catch (error) {
                console.error('Download failed:', error);
              }
            }}
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              backgroundColor: '#1a73e8',
              padding: '10px 20px',
              borderRadius: '5px',
              color: 'white'
            }}
          >
            <BiDownload />
            Export
          </a>
        </div>
        <div
          className="date-picker-container"
          style={{
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <DateRangePicker
            value={dateRange}
            onSelect={range => {
              setDateRange(
                moment.range(
                  range.start.utcOffset('+01:00').startOf('day'),
                  range.end.utcOffset('+01:00').endOf('day')
                )
              );
            }}
            minimumDate={moment()
              .subtract(1, 'year')
              .toDate()}
            maximumDate={moment().toDate()}
            singleDateRange={true}
          />
        </div>
        <div className="search_stats_table">
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: 'left',
                    padding: '10px',
                    borderBottom: '2px solid #eee'
                  }}
                >
                  Search Term
                </th>
                <th
                  style={{
                    textAlign: 'right',
                    padding: '10px',
                    borderBottom: '2px solid #eee'
                  }}
                >
                  Broj pretraga
                </th>
              </tr>
            </thead>
            <tbody>
              {searchStats &&
                searchStats.map((stat, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #eee'
                      }}
                    >
                      {stat.term}
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        padding: '10px',
                        borderBottom: '1px solid #eee'
                      }}
                    >
                      {stat.count}
                    </td>
                  </tr>
                ))}
              {(!searchStats || searchStats.length === 0) && (
                <tr>
                  <td
                    colSpan={2}
                    style={{ textAlign: 'center', padding: '20px' }}
                  >
                    No search data available for selected date range
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
