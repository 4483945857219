import types from './constants';

export const _updateSEO = params => ({
  type: types.UPDATE_SEO,
  params
});

export const _updateSEOStatic_Pages = params => ({
  type: types.UPDATE_SEO_STATIC_PAGES,
  params
});

export const _getStaticPagesSEO = () => ({
  type: types.GET_STATIC_PAGES
});

export const _setStaticPagesSEO = data => ({
  type: types.SET_STATIC_PAGES,
  payload: data
});
