import React from 'react';
import { useIntl } from 'react-intl';
import FormNav from './FormNav';
import PageTitle from 'helpers/PageTitle';

const WrapForm = ({
  error,
  data,
  children,
  active,
  setActive,
  navItems,
  pageTitle,
  skeleton,
  className = ''
}) => {
  const { formatMessage } = useIntl();

  const navbar = () =>
    navItems && (
      <FormNav active={active} setActive={setActive} items={navItems} />
    );

  return (
    <section className={`cms_page ${className}`}>
      <PageTitle name={formatMessage(pageTitle)} />
      {error ? (
        <div className="data_no_found">{formatMessage(error)}</div>
      ) : (
        <div className="box_holder">
          {data ? (
            <>
              {navbar()}
              {children}
            </>
          ) : (
            skeleton
          )}
        </div>
      )}
    </section>
  );
};

export default WrapForm;
