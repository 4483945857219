import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';
import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { fetchCategoriesWithSub } from './redux/actions';
import { makeSelectCategories } from './redux/selectors';
import messages from './messages';
import useUserKeyDown from 'helpers/useUserKeyDown';

const key = 'selects';

const CategorySelect = ({
  label,
  placeholder,
  name,
  value,
  setValue,
  errors,
  touched,
  only_parent
}) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [delay, setDelay] = useState('');
  const results = useSelector(makeSelectCategories());
  const [resTemp, setResTemp] = useState(results);
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isVisible && value && results.length) {
      let activeItem = results.map(item => item.id).indexOf(value.id);
      if (activeItem > -1) setActiveIndex(activeItem);
      else setActiveIndex(0);
    }
  }, [isVisible, results, value]);

  useEffect(() => {
    if (results?.length) {
      results.forEach(r => {
        if (r.subcategories) setResTemp([...results, ...r.subcategories]);
      });
    } else {
      setResTemp([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.length]);
  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(results.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex < results.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, results], () => {
    if (isVisible && results.length) handleClick(results[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) {
      if (only_parent) {
        dispatch(fetchCategoriesWithSub('&only_parent=1'));
      } else {
        dispatch(fetchCategoriesWithSub(''));
      }
    }
  }, [dispatch, isVisible, only_parent]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      if (only_parent) {
        dispatch(fetchCategoriesWithSub(`${text}&only_parent=0`));
      } else {
        dispatch(fetchCategoriesWithSub(text));
      }
    }, 500);
    setDelay(copyState);
    setActiveIndex(0);
  };

  const handleClick = item => {
    setValue(item);
    toggle();
  };

  return (
    <div className={activeClass('search_select_wrapper', isVisible)}>
      <div className="search_select_holder">
        <div className="root" onClick={toggle}>
          <p>
            {value ? (
              <span>
                {value.name ? value.name : value}
                <FaTimes onClick={() => setValue(null)} />
              </span>
            ) : (
              `${formatMessage(messages.choose)} ${placeholder}`
            )}
          </p>
          <span className="icon">
            <FaSortDown />
          </span>
        </div>
        <label>{label}</label>
        {isVisible && (
          <div className="dropdown_holder" ref={modal}>
            <div className="dropdown_content">
              <input
                type="text"
                placeholder={formatMessage(messages.search_placeholder)}
                onChange={handleChange}
                autoFocus
              />
              {resTemp.length ? (
                <ul>
                  {resTemp.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleClick(item)}
                      onMouseEnter={() => setActiveIndex(index)}
                      className={activeClass('', activeIndex === index)}
                    >
                      {(() => {
                        if (item.parent_id == null) {
                          return (
                            <span style={{ color: '#415587' }}>
                              <b>{item.name}</b>
                            </span>
                          );
                        } else {
                          return <span>{item.name}</span>;
                        }
                      })()}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no_result">{formatMessage(messages.no_result)}</p>
              )}
            </div>
          </div>
        )}
      </div>
      {errors[name] && touched[name] && (
        <p className="error_msg">
          {typeof errors[name] === 'object'
            ? formatMessage(errors[name])
            : errors[name]}
        </p>
      )}
    </div>
  );
};

export default CategorySelect;
