import React from 'react';
import MobileTableWrap from 'cms/components/Table/Mobile/MobileTableWrap';
import MobileTableRow from 'cms/components/Table/Mobile/MobileTableRow';
import messages from './messages';

const MobileTable = ({ item, opened }) => (
  <>
    {opened.includes(item.id) && (
      <MobileTableWrap>
        <MobileTableRow label={messages.category}>
          {item.category && item.category}
        </MobileTableRow>
        <MobileTableRow label={messages.name}>
          {item.name && item.name}
        </MobileTableRow>
        <MobileTableRow label={messages.lower}>
          {item.lower && item.lower}
        </MobileTableRow>
      </MobileTableWrap>
    )}
  </>
);

export default MobileTable;
