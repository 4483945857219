import React, { useState } from 'react';

export default function Variables({ addVariable, type, separator = true }) {
  return (
    <div className="variables_select">
      <p>Dodaj varijablu</p>
      <div
        className="variable"
        onClick={() => {
          addVariable('Name');
        }}
      >
        Name
      </div>

      {separator ? (
        <div
          className="variable"
          onClick={() => {
            addVariable('Separator');
          }}
        >
          Separator
        </div>
      ) : null}
      {type === 'product' ? (
        <>
          <div
            className="variable"
            onClick={() => {
              addVariable('Price');
            }}
          >
            Price
          </div>
          <div
            className="variable"
            onClick={() => {
              addVariable('Category');
            }}
          >
            Category
          </div>
        </>
      ) : null}
    </div>
  );
}
