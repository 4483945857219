/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeHomePagePosition,
  changeShockAction,
  deleteLandingPage,
  getLandingPages,
  getModels,
  setSuccess
} from './redux/actions';
import { makeSelectLandingPages, makeSelectModels } from './redux/selectors';
import NewLandingPage from './New';
import {
  Button,
  Card,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import config from '../../config';
import { FiPenTool, FiTrash } from 'react-icons/fi';
import DeleteModal from './components/DeleteModal';
import { useCallback } from 'react';
import EditLandingPage from './Edit';
import { push } from 'connected-react-router';
import history from 'utils/history';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { HiLink } from 'react-icons/hi';
import Checkbox from 'cms/components/Checkbox';

const key = 'landing_page';

const LandingPage = () => {
  const [newBool, setNewBool] = useState(false);
  const [editBool, setEditBool] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const landing_pages = useSelector(makeSelectLandingPages());

  useEffect(() => {
    dispatch(getLandingPages());
  }, []);

  useEffect(() => {
    console.log(landing_pages);
  }, [landing_pages]);

  const deletePage = useCallback(
    (...args) => dispatch(deleteLandingPage(...args)),
    [dispatch]
  );

  const changeShockActions = useCallback(
    (...args) => dispatch(changeShockAction(...args)),
    [dispatch]
  );

  const changeHomePage = useCallback(
    (...args) => dispatch(changeHomePagePosition(...args)),
    [dispatch]
  );

  const { push } = useHistory();

  useEffect(() => {
    if (!newBool) {
      dispatch(setSuccess(false));
    }
  }, [newBool]);

  return (
    <Card>
      <div className="landing_menu">
        {(newBool || editBool) && (
          <Button
            className="nazad_button"
            onClick={() => {
              setNewBool(false);
              setSelectedPage(null);
              setEditBool(false);
            }}
          >
            {'<'} Nazad
          </Button>
        )}
        {!newBool && !editBool && (
          <Button
            className="dodaj_button"
            onClick={() => {
              setNewBool(true);
              setSelectedPage(null);
              setEditBool(false);
            }}
          >
            Dodaj Landing Page
          </Button>
        )}
      </div>

      {newBool && <NewLandingPage newBool={setNewBool} />}

      {editBool && (
        <EditLandingPage newBool={setEditBool} id={selectedPage?.id} />
      )}

      {!newBool && !editBool && (
        <TableContainer>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="landing_table"
          >
            <TableHead>
              <TableRow style={{ backgroundColor: '#f7f7f7' }}>
                <TableCell align="left">ID</TableCell>
                <TableCell>Ime</TableCell>
                <TableCell align="left">Link</TableCell>

                <TableCell align="left">Šok akcije</TableCell>

                <TableCell align="left">Home Page</TableCell>
                <TableCell align="left">Akcije</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {landing_pages.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={index % 2 ? { backgroundColor: '#f7f7f7' } : {}}
                >
                  <TableCell component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell align="left">{row?.name}</TableCell>
                  <TableCell align="left">
                    <Link
                      onClick={() => {
                        window.open(
                          window.location.origin + '/' + row?.slug,
                          '_blank',
                          'noreferrer'
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <HiLink />
                      {row?.slug}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    <Checkbox
                      checked={row.shock_action === 1 ? true : false}
                      onClick={() =>
                        changeShockActions({
                          shock_action: row.shock_action === 1 ? false : true,
                          id: row.id
                        })
                      }
                      style={{ width: '20px', height: '20px' }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Checkbox
                      checked={row.home_page === 1 ? true : false}
                      onClick={() =>
                        changeHomePage({
                          home_page_position: row.home_page_position,
                          id: row.id,
                          home_page: row.home_page === 1 ? false : true
                        })
                      }
                      style={{ width: '20px', height: '20px' }}
                    />
                    <Select
                      value={row?.home_page_position?.toString() || '1'}
                      name="label"
                      onChange={e =>
                        changeHomePage({
                          home_page_position: Number(e.target.value),
                          id: row.id,
                          home_page: row.home_page === 1 ? true : false
                        })
                      }
                    >
                      <MenuItem key="pozicija 1" value="1">
                        Pozicija 1
                      </MenuItem>
                      <MenuItem key="pozicija 2" value="2">
                        Pozicija 2
                      </MenuItem>
                      <MenuItem key="pozicija 3" value="3">
                        Pozicija 3
                      </MenuItem>
                      <MenuItem key="pozicija 4" value="4">
                        Pozicija 4
                      </MenuItem>
                      <MenuItem key="pozicija 5" value="5">
                        Pozicija 5
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align="left">
                    <FiPenTool
                      className="icon_action"
                      onClick={() => {
                        setSelectedPage(row);
                        setEditBool(true);
                      }}
                    />
                    <FiTrash
                      className="icon_action"
                      onClick={() => {
                        setSelectedPage(row);
                        setDeleteModal(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {deleteModal && selectedPage && (
        <DeleteModal
          selected={selectedPage}
          setModal={setDeleteModal}
          deletePage={deletePage}
        />
      )}
    </Card>
  );
};

export default LandingPage;
