import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import {
  ShoppingCart,
  FavoriteBorder,
  ArrowRightAlt,
  Favorite
} from '@material-ui/icons';

import { makeSelectToken } from 'containers/App/selectors';
import { addItem } from 'views/ShoppingCart/redux/actions';
import { makeSelectItems } from 'views/ShoppingCart/redux/selectors';
import { addFavoriteItem } from 'views/WishList/redux/actions';
import { makeSelectFavoriteItems } from 'views/WishList/redux/selectors';

import { photoBreakpoints } from 'helpers/photoBreakpoints';
import Image from 'components/Image/Loadable';
// import DiscountPriceImg from 'assets/images/discount_price.png';
import ImagePlaceholder from 'assets/images/mini_logo.png';
import routes from 'routes';
import { formatCurrency } from 'utils/formatCurrency';
import styled, { css } from 'styled-components';
import AtionImg from 'assets/images/akcija.webp';

const CustomImg = styled(Image)`
  width: ${props => props.width || '38px'};
`;
const EnergyValueSticker = styled.div`
  background: url(${props => props?.img});
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  width: 100%;
  height: 20px;
  padding-left: 0;
  background-size: contain !important;
`;
const LabelWrapper = styled.a`
  display: flex;
  width: 240px;
`;

const LabelValueSticker = styled.div`
  background: ${props => props?.color};
  width: auto;
  position: absolute;
  z-index: 9;
  color: ${props =>
    props?.color === '#000000' || props?.color === '#0000FF' ? '#FFF' : '#000'};
  font-weight: 500;
  margin: 5px;
  margin-right: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px;
  height: 25px;
  display: flex;
  align-items: center;
`;
const ProductItem = ({ product, mainCatSlug }) => {
  const {
    active,
    id,
    name,
    slug,
    main_image,
    retail_price,
    web_price,
    discount,
    authenticated_price,
    mainCategory,
    main_category_slug,
    energy_value_sticker,
    catalog_stickers
  } = product;

  const title = name.length > 86 ? `${name.slice(0, 86)}...` : name;

  const dispatch = useDispatch();
  const { push } = useHistory();
  const token = useSelector(makeSelectToken());
  const { items } = useSelector(makeSelectItems());
  const favoriteItems = useSelector(makeSelectFavoriteItems());
  const storageUrl = `https://api.jakov.rs/storage/`;
  const discountImg = discount?.sticker
    ? `${storageUrl}${discount.sticker}`
    : null;
  const toToProduct = () => {
    if (main_category_slug) {
      push(`/kategorije/${main_category_slug}/proizvodi/${slug}`);
    } else {
      push(`/kategorije/${getUrl()}/proizvodi/${slug}`);
    }
  };
  const addToCart = e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(addItem({ product_id: id, quantity: 1 }));
    let items = {};
    items['item_id'] = id;
    items['item_name'] = name;
    items['item_category'] = main_category_slug;
    items['currency'] = 'RSD';
    let fPrice = 0;
    if (token) fPrice = authenticated_price.toFixed(2);
    else fPrice = web_price.toFixed(2);
    items['price'] = fPrice;
    items['quantity'] = 1;
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'RSD',
        value: fPrice,
        items: [items]
      }
    });
  };

  const goToShoppingCart = e => {
    e.stopPropagation();
    e.preventDefault();
    push(routes.SHOPPING_CART);
  };

  const addToFavorite = e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(addFavoriteItem({ product_id: id, quantity: 1 }));
    let items = {};
    items['item_id'] = id;
    items['item_name'] = name;
    items['item_category'] = main_category_slug;
    items['currency'] = 'RSD';
    let fPrice = 0;
    if (token) fPrice = authenticated_price.toFixed(2);
    else fPrice = web_price.toFixed(2);
    items['price'] = fPrice;
    items['quantity'] = 1;
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'add_to_wishlist',
      ecommerce: {
        currency: 'RSD',
        value: fPrice,
        items: [items]
      }
    });
  };

  const goToFavorite = e => {
    e.stopPropagation();
    e.preventDefault();
    push(routes.WISH_LIST);
  };

  const activePrice = () => {
    if (discount) return discount.price && discount.price.toFixed(2);
    else {
      if (token) return authenticated_price && authenticated_price.toFixed(2);
      else return web_price && web_price.toFixed(2);
    }
  };

  //const savingPrice = retail_price - activePrice();
  let savingPrice;
  let retailPrice;

  if (retail_price < activePrice()) {
    savingPrice = activePrice() * 1.14 - activePrice();
    retailPrice = activePrice() * 1.14;
  } else {
    savingPrice = retail_price - activePrice();
    retailPrice = retail_price;
  }

  const imgUrl = storageUrl + energy_value_sticker;
  const getUrl = () => {
    let categorySlug = '';
    if (mainCategory && mainCategory.length && mainCategory[0].slug)
      categorySlug = mainCategory[0].slug;
    else if (main_category_slug) categorySlug = main_category_slug;
    else {
      if (mainCatSlug) categorySlug = mainCatSlug;
    }

    return categorySlug;
  };

  const getCategoryName = () => {
    let categoryName = '';
    if (mainCategory && mainCategory.length && mainCategory[0].name)
      categoryName = mainCategory[0].name;
    else if (main_category_slug) categoryName = main_category_slug;
    else {
      if (mainCatSlug) categoryName = mainCatSlug;
    }

    return categoryName;
  };

  return (
    <a
      href={`/kategorije/${getUrl()}/proizvodi/${slug}`}
      className="product_item_wrap"
      style={{ position: 'relative' }}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        toToProduct();
      }}
    >
      <div
        className={`product_item_holder ${
          catalog_stickers && catalog_stickers.length ? 'have-padding-top' : ''
        }`}
      >
        {/* {catalog_stickers &&
          catalog_stickers.map(sticker => {
            if (!sticker.label_active) return null;
            return (
              <LabelWrapper href="/" className="label-wrapper">
                <LabelValueSticker color={`#${sticker.label}`}>
                  {sticker.label_text}
                </LabelValueSticker>
              </LabelWrapper>
            );
          })} */}
        <div
          className="icons"
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            zIndex: 2
          }}
        >
          {active ? (
            favoriteItems &&
            favoriteItems.items &&
            favoriteItems.items.length &&
            favoriteItems.items.find(el => el.id === id) ? (
              <Favorite className="favorite_icon a" onClick={goToFavorite} />
            ) : (
              <FavoriteBorder
                className="favorite_icon"
                onClick={addToFavorite}
              />
            )
          ) : null}
        </div>
        <div className="photo">
          {discount && !discount.type ? (
            <CustomImg width="50px" src={AtionImg} className="discount_icon" />
          ) : null}
          {discountImg && (
            <CustomImg
              width="23%"
              src={discountImg}
              className="discount_icon"
            />
          )}
          {catalog_stickers && (
            <div
              style={{
                position: 'absolute',
                top: '30px',
                right: '20px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {catalog_stickers.map(sticker => {
                if (!sticker.sticker_active) return null;
                if (
                  !sticker.sticker ||
                  sticker.sticker === 'undefined' ||
                  sticker.sticker === 'null'
                )
                  return null;
                const stickerImg = `${storageUrl}${sticker.sticker}`;
                return (
                  <CustomImg
                    src={stickerImg}
                    className="discount_icon catalog_sticker"
                    style={{
                      position: 'initial',
                      marginBottom: '5px',
                      height: '100%'
                    }}
                  />
                );
              })}
            </div>
          )}

          {main_image ? (
            <Image
              src={photoBreakpoints(main_image)}
              alt={getCategoryName() + ' - ' + name}
              title={getCategoryName() + ' - ' + name}
              className="product_img"
            />
          ) : (
            <Image
              src={ImagePlaceholder}
              alt={getCategoryName() + ' - ' + name}
              title={getCategoryName() + ' - ' + name}
              className="img_placeholder"
            />
          )}
        </div>
        <div className="details">
          <h2>{title}</h2>
          <div
            className={active ? 'details_content' : 'details_content hidden'}
          >
            {discountImg && (
              <CustomImg src={discountImg} className="discount_icon" />
            )}
            <p className="web_price_text">Maloprodajna cena</p>
            <div className="web_price_container">
              <div className="web_price">
                {activePrice() !== retailPrice && (
                  <>
                    {/* {retail_price} */}
                    {retailPrice && formatCurrency(retailPrice)}

                    {/* <span>RSD</span> */}
                  </>
                )}
              </div>
              <sup>RSD</sup>
            </div>
            <div className="price">
              {formatCurrency(activePrice())}
              {/* {activePrice().trim(2)} */}
              <sup>RSD</sup>
              {/* <span>RSD</span> */}
            </div>
            <div className="saving">
              Ušteda: <span>{formatCurrency(savingPrice)}</span>
              {` `}RSD
              {/* <span>RSD</span> */}
            </div>
            {/* {items && items.length && items.find(el => el.id === id) ? (
              <IconButton className="icon_button" onClick={goToShoppingCart}>
                <ArrowRightAlt />
              </IconButton>
            ) : (
              <IconButton className="icon_button" onClick={addToCart}>
                <ShoppingCart />
              </IconButton>
            )} */}
            <button className="add_to_cart" onClick={addToCart}>
              Dodaj u korpu
            </button>
            <p className="info_text">
              <strong>*Uloguj se</strong> i ostvari dodatni popust
            </p>
            {energy_value_sticker && <EnergyValueSticker img={imgUrl} />}
          </div>
        </div>
      </div>
    </a>
  );
};

export default ProductItem;
