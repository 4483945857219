import produce from 'immer';
import types from './constants';

export const initialState = {
  categories: {},
  params: { main: 1 },
  singleCategory: null,
  singleCategoryError: null,
  createCategoryIsPending: false,
  updateCategoryIsPending: false,
  attributeValues: [],
  searchStats: []
};

/* eslint-disable default-case */
const categoriesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
      case types.SET_SEARCH_STATS:
        draft.searchStats = action.payload;
        break;
      case types.SET_SINGLE_CATEGORY:
        draft.singleCategory = action.payload;
        break;
      case types.FETCH_SINGLE_CATEGORY_ERROR:
        draft.singleCategoryError = action.error;
        break;
      case types.CLEAR_SINGLE_CATEGORY:
        draft.singleCategory = null;
        draft.singleCategoryError = null;
        break;
      case types.SET_PARAMS:
        draft.params = action.payload;
        break;
      case types.CLEAR_PARAMS:
        draft.params = { main: 1 };
        break;
      case types.ADD_CATEGORY_REQUEST:
        draft.createCategoryIsPending = true;
        break;
      case types.ADD_CATEGORY_SUCCESS:
      case types.ADD_CATEGORY_ERROR:
        draft.createCategoryIsPending = false;
        break;
      case types.UPDATE_CATEGORY_REQUEST:
        draft.updateCategoryIsPending = true;
        break;
      case types.UPDATE_CATEGORY_SUCCESS:
      case types.UPDATE_CATEGORY_ERROR:
        draft.updateCategoryIsPending = false;
        break;
      case types.SET_ATTRIBUTE_VALUES:
        draft.attributeValues = action.payload;
        break;
    }
  });

export default categoriesReducer;
