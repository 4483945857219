import React from 'react';
import SkeletonElement from 'components/SkeletonElement';

const Skeleton = () => (
  <div className="skeleton_inner classic_form">
    <div className="skeleton_wrapper">
      <div className="inputs_group">
        <div className="field">
          <SkeletonElement type="label" />
          <SkeletonElement type="input" />
        </div>
        <div className="field">
          <SkeletonElement type="label" />
          <SkeletonElement type="input" />
        </div>
        <div className="field">
          <SkeletonElement type="label" />
          <SkeletonElement type="input" />
        </div>
      </div>
      <div className="buttons jc_end">
        <SkeletonElement type="button" />
        <SkeletonElement type="button" />
      </div>
    </div>
  </div>
);

export default Skeleton;
