import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import {
  getCatalogs,
  getLandingPageError,
  setCatalog,
  setLandingPages,
  setModels,
  setProducts,
  getLandingPages,
  setLandingPage,
  setCategories,
  setSuccess
} from './actions';
import types from './constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';

export function* fetchCatalogs({ params }) {
  try {
    const res = yield call(request, {
      url: 'admin/catalog-sticker-types',
      method: 'get',
      params
    });
    yield put(setCatalog(res?.data));
  } catch (error) {}
}

export function* pickCatalog({ slug }) {
  try {
    const res = yield call(request, {
      url: 'catalog-stickers/' + slug,
      method: 'get'
    });
    yield put(setProducts(res?.data));
  } catch (error) {}
}

export function* submitLandingPage({ data }) {
  try {
    const res = yield call(request, {
      url: 'admin/landing-pages',
      method: 'post',
      data: data
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(setSuccess(true));
    yield put(getLandingPages());
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(enqueueSnackbar({ message: error.data.message }, 'danger'));
  }
}

export function* getModels() {
  try {
    const res = yield call(request, {
      url: 'admin/landing-page-models',
      method: 'get'
    });

    yield put(setModels(res.data));
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(enqueueSnackbar({ message: error.data.message }, 'danger'));
  }
}

export function* _getLandingPages({ params }) {
  try {
    const res = yield call(request, {
      url: 'admin/landing-pages',
      method: 'get',
      params: params
    });

    yield put(setLandingPages(res.data));
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(enqueueSnackbar({ message: error.data.message }, 'danger'));
  }
}

export function* _deleteLandingPage({ id }) {
  try {
    const res = yield call(request, {
      url: 'admin/landing-pages/' + id,
      method: 'delete'
    });

    yield put(getLandingPages());
    yield put(enqueueSnackbar({ message: res.data.message }));
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(enqueueSnackbar({ message: error.data.message }, 'danger'));
  }
}

export function* _getLandingPage({ id }) {
  try {
    const res = yield call(request, {
      url: 'admin/landing-pages/' + id,
      method: 'get'
    });

    yield put(setLandingPage(res.data));
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(enqueueSnackbar({ message: error?.data?.message }, 'danger'));
  }
}

export function* updateLandingPage({ id, data }) {
  try {
    const res = yield call(request, {
      url: 'admin/landing-pages/' + id + '?_method=patch',
      method: 'post',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(setSuccess(true));
    yield put(getLandingPages());
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(enqueueSnackbar({ message: error.data.message }, 'danger'));
  }
}

export function* fetchCategories() {
  try {
    const res = yield call(request, {
      url: 'admin/categories?paginate=1000&search=&include=parent',
      method: 'get'
    });
    yield put(setCategories(res?.data));
  } catch (error) {}
}

export function* changeShock({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/landing-pages/${payload.id}/shock-action`,
      method: 'post',
      data: { shock_action: payload.shock_action }
    });
    if (res?.data) {
      yield put(enqueueSnackbar({ message: res.data.message }));
      yield put(getLandingPages());
    }
    yield put(
      enqueueSnackbar({ message: res?.data?.message || 'Neuspesno' }, 'danger')
    );
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(
      enqueueSnackbar(
        { message: error?.data?.message || 'Neuspesno' },
        'danger'
      )
    );
  }
}

export function* changeHomePagePosition({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/landing-pages/${payload.id}/home-page`,
      method: 'post',
      data: {
        home_page: payload.home_page,
        position_home_page: payload.home_page_position
      }
    });
    if (res?.data) {
      yield put(enqueueSnackbar({ message: res.data.message }));
      yield put(getLandingPages());
    } else {
      yield put(
        enqueueSnackbar({ message: res?.data.message || 'Neuspesno' }, 'danger')
      );
    }
  } catch (error) {
    yield put(getLandingPageError(error));
    yield put(
      enqueueSnackbar(
        { message: error?.data?.message || 'Neuspesno' },
        'danger'
      )
    );
  }
}

export default function* cmsLandingPage() {
  yield takeLatest(types.GET_CATALOG, fetchCatalogs);
  yield takeLatest(types.PICK_CATALOG, pickCatalog);
  yield takeLatest(types.SUBMIT_LANDING_PAGE, submitLandingPage);
  yield takeLatest(types.GET_LANDING_PAGE_MODELS, getModels);
  yield takeLatest(types.GET_LANDING_PAGES, _getLandingPages);
  yield takeLatest(types.DELETE_LANDING_PAGE, _deleteLandingPage);
  yield takeLatest(types.GET_LANDING_PAGE, _getLandingPage);
  yield takeLatest(types.UPDATE_LANDING_PAGE, updateLandingPage);
  yield takeLatest(types.FETCH_CATEGORIES, fetchCategories);
  yield takeLatest(types.CHANGE_SHOCK_ACTION, changeShock);
  yield takeLatest(types.CHANGE_HOME_PAGE_POSITION, changeHomePagePosition);
}
