import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'cms/components/Selects/redux/reducer';
import saga from 'cms/components/Selects/redux/saga';
import { fetchCategories } from 'cms/components/Selects/redux/actions';
import { makeSelectCategories } from 'cms/components/Selects/redux/selectors';

import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import useUserKeyDown from 'helpers/useUserKeyDown';
import messages from 'cms/components/Selects/messages';
import productMessages from '../../messages';

const key = 'selects';

const CategorySelect = ({
  handleSubmit,
  handleClose,
  params,
  ids,
  selected,
  setSelected
}) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [delay, setDelay] = useState('');
  const results = useSelector(makeSelectCategories());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(results.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex < results.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, results], () => {
    if (isVisible && results.length) handleClick(results[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) dispatch(fetchCategories(''));
  }, [dispatch, isVisible]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(fetchCategories(`${text}`));
    }, 500);
    setDelay(copyState);
    setActiveIndex(0);
  };

  const handleClick = item => {
    const { paginate, page: tmp, ...rest } = params;
    setSelected(item);
    let payload = { category_id: item.id };
    if (ids.length) payload.products = ids;
    handleSubmit(payload, rest, handleClose);
    toggle();
  };

  return (
    <div className={activeClass('search_select_wrapper', isVisible)}>
      <div className="search_select_holder">
        <div className="root" onClick={toggle}>
          <p>
            {selected ? (
              <span>
                {selected.name}
                <FaTimes onClick={() => setSelected(null)} />
              </span>
            ) : (
              formatMessage(productMessages.choose_category)
            )}
          </p>
          <span className="icon">
            <FaSortDown />
          </span>
        </div>
        <label>{formatMessage(productMessages.choose_category)}</label>
        {isVisible && (
          <div className="dropdown_holder" ref={modal}>
            <div className="dropdown_content">
              <input
                type="text"
                placeholder={formatMessage(messages.search_placeholder)}
                onChange={handleChange}
                autoFocus
              />
              {results.length ? (
                <ul>
                  {results.map((item, index) => (
                    <li
                      key={item.id}
                      onClick={() => handleClick(item)}
                      onMouseEnter={() => setActiveIndex(index)}
                      className={activeClass('', activeIndex === index)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no_result">{formatMessage(messages.no_result)}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategorySelect;
