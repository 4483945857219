import React from 'react';

const TableRow = ({ children, index }) => (
  <div
    className="table_row"
    style={
      index % 2 === 1
        ? {
            background: '#fff'
          }
        : {}
    }
  >
    {children}
  </div>
);

export default TableRow;
