import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from 'utils/request';
import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import {
  fetchCategories,
  setCategories,
  setSingleCategory,
  fetchSingleCategoryError,
  addCategorySuccess,
  addCategoryError,
  updateCategorySuccess,
  updateCategoryError,
  changeOrder,
  fetchSingleCategory,
  setAttributeValues,
  setSearchStats
} from './actions';
import types from './constants';
import { makeSelectParams } from './selectors';
import routes from 'routes';
import { convertToRaw } from 'draft-js';

const BASE_URL = 'admin/categories';

export function* getCategories({ payload, include }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}${include}`,
      method: 'get',
      params: payload
    });

    yield put(setCategories(res));
  } catch (error) {}
}

export function* getSingleCategory({ payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}?include=parent,declaration`,
      method: 'get'
    });
    try {
      const seo = yield call(request, {
        url: `/seo-categories/${res.data.slug}`,
        method: 'get'
      });

      res.data.seo = seo.data;
    } catch {}

    yield put(setSingleCategory(res.data));
  } catch (error) {}
}

export function* addCategory({ payload, meta: { setErrors } }) {
  const {
    parent_id,
    name,
    prefix,
    pdv,
    web_margin,
    retail_margin,
    dealer_margin,
    description_time,
    min_price,
    image,
    allowed_rebate,
    declaration_name,
    country_of_origin,
    consumer_rights,
    top_description,
    bottom_description
  } = payload;
  const formData = new FormData();
  if (parent_id) formData.append(`parent_id`, parent_id);
  if (image) formData.append(`image`, image, image.name);
  formData.append(`name`, name);
  formData.append(`prefix`, prefix);
  formData.append(`pdv`, pdv);
  formData.append(`web_margin`, web_margin);
  formData.append(`retail_margin`, retail_margin);
  formData.append(`dealer_margin`, dealer_margin);
  formData.append(`description_time`, description_time);
  formData.append(`min_price`, min_price);
  formData.append(`allowed_rebate`, allowed_rebate);
  formData.append(`declaration_name`, declaration_name);
  formData.append(`country_of_origin`, country_of_origin);
  formData.append(`consumer_rights`, consumer_rights);
  try {
    const res = yield call(request, {
      url: BASE_URL,
      method: 'post',
      data: formData
    });
    if (res.data && (bottom_description || top_description)) {
      let data = payload;
      data.top_description = data.top_description
        ? JSON.stringify(convertToRaw(data.top_description.getCurrentContent()))
        : '';
      data.bottom_description = data.bottom_description
        ? JSON.stringify(
            convertToRaw(data.bottom_description.getCurrentContent())
          )
        : '';
      const fdDesc = new FormData();
      if (data.top_description)
        fdDesc.append('top_description', data.top_description);
      if (data.bottom_description)
        fdDesc.append('bottom_description', data.bottom_description);
      yield call(request, {
        url: `${BASE_URL}/${res.data.id}/description`,
        method: 'patch',
        data: fdDesc
      });
    }

    yield put(addCategorySuccess());
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(push(routes.CMS_CATEGORIES));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(addCategoryError());
  }
}

export function* updateCategory({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}`,
      method: 'patch',
      data: payload
    });
    if (payload.bottom_description || payload.top_description) {
      // const fdDesc = new FormData();
      // if (payload.top_description)
      //   fdDesc.append('top_description', payload.top_description);
      // if (payload.bottom_description)
      //   fdDesc.append('bottom_description', payload.bottom_description);
      let data = payload;
      data.top_description = data.top_description
        ? JSON.stringify(convertToRaw(data.top_description.getCurrentContent()))
        : '';
      data.bottom_description = data.bottom_description
        ? JSON.stringify(
            convertToRaw(data.bottom_description.getCurrentContent())
          )
        : '';
      yield call(request, {
        url: `${BASE_URL}/${id}/description`,
        method: 'patch',
        data: {
          top_description: data.top_description,
          bottom_description: data.bottom_description
        }
      });
    }
    yield put(updateCategorySuccess());
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(push(routes.CMS_CATEGORIES));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateCategoryError());
  }
}

export function* deleteCategory({ payload, items, setItems }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: 'delete'
    });

    let filtered = items.filter(item => item.id !== payload);
    let orders = filtered.map((item, i) => ({ ...item, order: i + 1 }));
    setItems(orders);
    let sorted = filtered.map((item, i) => ({ id: item.id, order: i + 1 }));
    yield put(changeOrder({ category_orders: sorted }));

    yield put(enqueueSnackbar({ message: res.data.message }));
    const params = yield select(makeSelectParams());
    yield put(fetchCategories(params, '?include=all_subcategories'));
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put(
          enqueueSnackbar({ message: error.data.errors.message[0] }, 'danger')
        );
      }
    }
  }
}

export function* handleChangeOrder({ payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}-orders`,
      method: 'patch',
      data: payload
    });
  } catch (error) {}
}

export function* handleAddAttribute({
  id,
  payload,
  meta: { setErrors, resetForm }
}) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}/attributes`,
      method: 'post',
      data: payload
    });
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(id));
    yield call(resetForm, { attribute_id: '' });
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* updateAttribute({
  category_id,
  payload,
  meta: { setErrors },
  close
}) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${category_id}/attributes`,
      method: 'patch',
      data: payload
    });

    yield put(fetchSingleCategory(category_id));
    yield close();
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* deleteAttribute({ category_id, payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/attributes`,
      method: 'delete',
      data: payload
    });
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
  } catch (error) {}
}

export function* changeAttributeOrderTableRequest({ id, payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${id}/attributes-orders`,
      method: 'patch',
      data: payload
    });
  } catch (error) {}
}

export function* addNewAttributeValue({
  category_id,
  attribute_id,
  payload,
  close,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/attributes/${attribute_id}/values-new`,
      method: 'post',
      data: payload
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
    yield close();
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* addAttributeValue({
  category_id,
  payload,
  close,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/attribute-values`,
      method: 'post',
      data: payload
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
    yield close();
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* updateAttributeValue({
  category_id,
  payload,
  close,
  meta: { setErrors }
}) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${category_id}/attribute-values`,
      method: 'patch',
      data: payload
    });

    yield put(fetchSingleCategory(category_id));
    yield close();
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* changeAttributeValueOrder({ category_id, payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${category_id}/values-orders`,
      method: 'patch',
      data: payload
    });
  } catch (error) {}
}

export function* fetchAttributeValues({ id, params }) {
  try {
    const { data } = yield call(request, {
      url: `admin/attributes/${id}/values`,
      method: 'get',
      params
    });

    yield put(setAttributeValues(data));
  } catch (error) {}
}

export function* deleteAttributeValue({ category_id, payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/attribute-values`,
      method: 'delete',
      data: payload
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
  } catch (error) {}
}

export function* createAttribute({
  category_id,
  payload,
  meta: { setErrors, resetForm }
}) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${category_id}/attributes-new`,
      method: 'post',
      data: payload
    });

    yield put(enqueueSnackbar({ message: data.message }));
    yield put(fetchSingleCategory(category_id));
    yield call(resetForm, { attribute_id: null, attribute: '' });
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* updateImage({ category_id, image }) {
  const formData = new FormData();
  formData.append('image', image, image.name);

  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/image`,
      method: 'post',
      data: formData
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
  } catch (error) {}
}

export function* deleteImage({ category_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/image`,
      method: 'delete'
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
  } catch (error) {}
}

export function* updateDeclaration({
  category_id,
  payload,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${category_id}/declarations`,
      method: 'patch',
      data: payload
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(fetchSingleCategory(category_id));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* changeAttributeStatus({ category_id, attribute_id, payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${category_id}/attributes/${attribute_id}/status`,
      method: 'patch',
      data: payload
    });

    yield put(fetchSingleCategory(category_id));
  } catch (error) {}
}

export function* changeAttributeValueStatus({
  category_id,
  attribute_id,
  payload
}) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${category_id}/attributes/${attribute_id}/value-status`,
      method: 'patch',
      data: payload
    });

    yield put(fetchSingleCategory(category_id));
  } catch (error) {}
}

export function* getSearchStats({ payload }) {
  try {
    const res = yield call(request, {
      url: `/admin/search-statistics`,
      method: 'get',
      params: payload
    });
    yield put(setSearchStats(Object.values(res)));
  } catch (error) {}
}

export default function* categoriesSaga() {
  yield takeLatest(types.GET_SEARCH_STATS, getSearchStats);
  yield takeLatest(types.FETCH_CATEGORIES, getCategories);
  yield takeLatest(types.FETCH_SINGLE_CATEGORY, getSingleCategory);
  yield takeLatest(types.ADD_CATEGORY_REQUEST, addCategory);
  yield takeLatest(types.UPDATE_CATEGORY_REQUEST, updateCategory);
  yield takeLatest(types.DELETE_CATEGORY_REQUEST, deleteCategory);
  yield takeLatest(types.CHANGE_ORDER_REQUEST, handleChangeOrder);
  yield takeLatest(types.ADD_ATTRIBUTE_REQUEST, handleAddAttribute);
  yield takeLatest(types.UPDATE_ATTRIBUTE_REQUEST, updateAttribute);
  yield takeLatest(types.DELETE_ATTRIBUTE_REQUEST, deleteAttribute);
  yield takeLatest(
    types.CHANGE_ATTRIBUTE_ORDER_TABLE_REQUEST,
    changeAttributeOrderTableRequest
  );
  yield takeLatest(types.ADD_NEW_ATTRIBUTE_VALUE_REQUEST, addNewAttributeValue);
  yield takeLatest(types.ADD_ATTRIBUTE_VALUE_REQUEST, addAttributeValue);
  yield takeLatest(types.UPDATE_ATTRIBUTE_VALUE_REQUEST, updateAttributeValue);
  yield takeLatest(
    types.CHANGE_ATTRIBUTE_VALUES_ORDER,
    changeAttributeValueOrder
  );
  yield takeLatest(types.DELETE_ATTRIBUTE_VALUE, deleteAttributeValue);
  yield takeLatest(types.CREATE_ATTRIBUTE_REQUEST, createAttribute);
  yield takeLatest(types.UPDATE_IMAGE, updateImage);
  yield takeLatest(types.DELETE_IMAGE, deleteImage);
  yield takeLatest(types.UPDATE_DECLARATION, updateDeclaration);
  yield takeLatest(types.GET_ATTRIBUTE_VALUES, fetchAttributeValues);
  yield takeLatest(types.CHANGE_ATTRIBUTE_STATUS, changeAttributeStatus);
  yield takeLatest(
    types.CHANGE_ATTRIBUTE_VALUE_STATUS,
    changeAttributeValueStatus
  );
}
