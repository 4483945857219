import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { activeClass } from 'helpers/activeClass';
import Image from 'components/Image/Loadable';
import Logo from 'assets/images/jakov-logo.webp';
// import Logo1 from 'assets/images/logo.png';
import Search from './Search';
import Icons from '../Icons';

const MiddleBar = ({ scrolled, setScrolled, setSearchResults }) => {
  const handleScroll = useCallback(() => {
    if (window.scrollY > 200) setScrolled(true);
    else setScrolled(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header className={activeClass('header', scrolled)}>
      <div className="container">
        <div className="header_content">
          <div className="left_side">
            <Link to="/" className="logo_link">
              <Image src={Logo} />
            </Link>
          </div>
          <div className="middle">
            <Search setSearchResults={setSearchResults} />
            <div className="phone_numbers">
              <a href="tel: 011 630 50 33">011 630 50 33</a>
              <a href="tel: 018 41 55 222">018 41 55 222</a>
              <a href="tel: 066 8 220 220">066 8 220 220</a>
            </div>
          </div>
          <Icons />
        </div>
      </div>
    </header>
  );
};

export default MiddleBar;
