import React, { useEffect } from 'react';
import _ from 'lodash';
import { activeClass } from 'helpers/activeClass';

const Pagination = ({ setCurrentPage, params, meta, isInline, type }) => {
  const { total, per_page, current_page, last_page } = meta;

  const getPerPage = () => {
    if (type && type === 'cms') {
      if (per_page <= 20) return 20;
      if (per_page <= 50 && per_page > 20) return 50;
      if (per_page <= 100 && per_page > 50) return 100;
    } else {
      if (per_page <= 24) return 24;
      if (per_page <= 48 && per_page > 24) return 48;
      if (per_page <= 72 && per_page > 48) return 72;
      if (per_page <= 96 && per_page > 72) return 72;
    }
  };
  const pagesCount = last_page || Math.ceil(total / getPerPage());

  const pages = _.range(1, isNaN(pagesCount) ? 0 + 1 : pagesCount + 1);

  useEffect(() => {
    if (pagesCount === 1) {
      if (current_page !== 1) handleSetPage(current_page - 1);
    }
  }, [pagesCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const showNextPage = () => {
    if (current_page < pages[pages.length - 1]) handleSetPage(current_page + 1);
  };

  const showPrevPage = () => {
    if (current_page > 1) handleSetPage(current_page - 1);
  };

  const handleSetPage = page => {
    if (current_page !== page) {
      if (isInline) setCurrentPage(page);
      else setCurrentPage({ ...params, page });
    }
  };

  const items = () => {
    const isLastest = current_page > pages.length - 3;
    const startIndex = isLastest ? pages.length - 3 : current_page - 1;
    const lastIndex = isLastest ? pages.length : current_page + 2;
    return isLonger ? pages.slice(startIndex, lastIndex) : pages;
  };

  const isLonger = pages.length > 5;

  return (
    <nav className="pagination_nav">
      <ul>
        <li onClick={showPrevPage}>&#x276E;</li>
        {isLonger && current_page > 1 && (
          <li onClick={() => handleSetPage(1)}>1</li>
        )}
        {isLonger && current_page > 2 && <li>...</li>}
        {items().map(page => (
          <li
            key={page}
            onClick={() => handleSetPage(page)}
            className={activeClass('page_item', page === current_page)}
          >
            {page}
          </li>
        ))}
        {isLonger && pages.length - 3 > current_page && <li>...</li>}
        {isLonger && pages.length - 2 > current_page && (
          <li onClick={() => handleSetPage(pages.length)}>{pages.length}</li>
        )}
        <li onClick={showNextPage}>&#x276F;</li>
      </ul>
    </nav>
  );
};

export default Pagination;
