import produce from 'immer';
import types from './constants';

export const initialState = {
  categories: null,
  brands: null,
  conditions: null,
  condition: null,
  exclusions: null,
  exclusion: null
};

/* eslint-disable default-case */
const recommendedPricesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
      case types.SET_BRANDS:
        draft.brands = action.payload;
        break;
      case types.SET_CONDITIONS:
        draft.conditions = action.payload;
        break;
      case types.SET_CONDITION:
        console.log('CONDITION SET', action.payload);
        draft.condition = action.payload;
        break;
      case types.SET_EXCLUSIONS:
        draft.exclusions = action.payload;
        break;
      case types.SET_EXCLUSION:
        draft.exclusion = action.payload;
        break;
    }
  });

export default recommendedPricesReducer;
