import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image/Loadable';

import LeftBannerPhoto from 'assets/images/banner-left.webp';
import RightBannerPhoto from 'assets/images/banner-right.webp';
import MobileLeftBannerPhoto from 'assets/images/mobile-banner-left.webp';
import MobileRightBannerPhoto from 'assets/images/mobile-banner-right.webp';
import { activeClass } from 'helpers/activeClass';
import routes from 'routes';

const Banner = ({ scrolled }) => (
  <div className="banner_wrapper">
    <div className="container">
      <div className="banner">
        <a href="tel:066 8 220 220" className="border">
          Pozovite nas
        </a>
        <a href="mailto:info@jakovsistem.com"  className="border">info@jakovsistem.com</a>
        <a href="mailto:beograd@jakovsistem.com">beograd@jakovsistem.com</a>
      </div>
    </div>
  </div>
);

export default Banner;
