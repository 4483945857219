import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiMic, FiMicOff, FiSearch } from 'react-icons/fi';
import { firstClick, search } from 'components/AppBar/redux/actions';
import useUserKeyPress from 'helpers/useUserKeyPress';
import { useHistory } from 'react-router-dom';
import SpeechRecognition, {
  useSpeechRecognition
} from 'react-speech-recognition';

let focused = false;

const Search = ({ setSearchResults }) => {
  const history = useHistory();
  const [delay, setDelay] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  useUserKeyPress(27, e => {
    e.preventDefault();
    inputRef.current.blur();
  });

  useUserKeyPress(191, e => {
    if (!focused) {
      e.preventDefault();
      inputRef.current.focus();
      focused = true;
    }
  });
  useEffect(() => {
    if (window.location.pathname === '/') inputRef.current.value = '';
  }, []);

  const handleFirstClick = () => {
    dispatch(firstClick());
    setSearchResults(true);
  };

  const handleChange = e => {
    const q = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      if (q.length) dispatch(search(q));
      else dispatch(firstClick());
      setSearchResults(true);
    }, 500);
    setDelay(copyState);
  };

  const handleSearch = () => {
    setSearchResults(false);
    history.push(`/pretraga-proizvoda/${inputRef.current.value}`);
  };
  const handleSearchOnEnter = event => event.which === 13 && handleSearch();

  useEffect(() => {
    setLoaded(true);
  }, []);

  // MICROFONE
  const { transcript, listening } = useSpeechRecognition();

  useEffect(() => {
    if (!transcript) return;
    inputRef.current.value = transcript;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      if (transcript.length) dispatch(search(transcript));
      else dispatch(firstClick());
      setSearchResults(true);
    }, 500);
    setDelay(copyState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript]);

  const handleMicrofone = () => {
    listening
      ? SpeechRecognition.stopListening()
      : SpeechRecognition.startListening({ language: 'sr-SP' });
  };

  return (
    <div className="search_wrapper">
      {/* <span style={{ backgroundColor: 'white' }}>
        <FiSearch />
      </span> */}
      <input
        onClick={() => {
          if (!inputRef.current.value || !loaded) {
            handleFirstClick();
          } else {
            setSearchResults(true);
          }
        }}
        type="text"
        placeholder="Pretraga Proizvoda"
        onChange={handleChange}
        ref={inputRef}
        onFocus={() => (focused = true)}
        onBlur={() => (focused = false)}
        onKeyPress={handleSearchOnEnter}
      />
      <div className="microfone_off" onClick={handleMicrofone}>
        {listening ? (
          <FiMic style={{ margin: 'auto' }} />
        ) : (
          <FiMicOff style={{ margin: 'auto' }} />
        )}
      </div>
      <button onClick={handleSearch}>Pretraži</button>
    </div>
  );
};

export default Search;
