import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useInjectSaga } from 'utils/injectSaga';
import saga from './redux/saga';
import { newsletterSubscribe } from './redux/actions';
import { Link } from 'react-router-dom';

const key = 'newsletter_subscribe';

const Newsletter = () => {
  useInjectSaga({ key, saga });

  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    if (email.length) {
      dispatch(newsletterSubscribe(email));
      setEmail('');
    }
  };

  return (
    <div className="column newsletter">
      <div className="title_holder">
        <h2>Prijavi se za Newsletter!</h2>
        <div className="line" />
      </div>
      <form className="input_wrap" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Vaš E-email *"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <button type="submit">Prijavi se</button>
      </form>
      <h3>Radno vreme Call Centra</h3>
      <p>Ponedeljak – Petak: od 08h do 19h</p>
      <p>Subota: od 09 do 15h</p>
      <h4>Radno vreme u Beogradu</h4>
      <p>Ponedeljak – Petak: od 08h do 17h</p>
      <p>Subota: od 09 do 15h</p>
      <h4>Servis</h4>
      <p>Ponedeljak – Petak: od 08h do 17h</p>
      <button className="submit">
        <a href="http://b2b.jakovsistem.com:8085/" target="_blank">
          Prijava za B2B
        </a>
      </button>
    </div>
  );
};

export default Newsletter;
