import types from './constants';

export const getCatalogs = params => ({
  type: types.GET_CATALOG,
  params
});

export const setCatalog = payload => ({
  type: types.SET_CATALOG,
  payload
});

export const setProducts = payload => ({
  type: types.SET_PRODUCTS,
  payload
});

export const pickCatalog = slug => ({
  type: types.PICK_CATALOG,
  slug
});

export const submitLandingPage = data => ({
  type: types.SUBMIT_LANDING_PAGE,
  data
});

export const getLandingPageError = error => ({
  type: types.GET_ERROR,
  error
});

export const getModels = () => ({
  type: types.GET_LANDING_PAGE_MODELS
});

export const setModels = payload => ({
  type: types.SET_LANDING_PAGE_MODELS,
  payload
});

export const getLandingPages = params => ({
  type: types.GET_LANDING_PAGES,
  params
});
export const setLandingPages = payload => ({
  type: types.SET_LANDING_PAGES,
  payload
});

export const getLandingPage = id => ({
  type: types.GET_LANDING_PAGE,
  id
});
export const setLandingPage = payload => ({
  type: types.SET_LANDING_PAGE,
  payload
});

export const updateLandingPage = (id, data) => ({
  type: types.UPDATE_LANDING_PAGE,
  id,
  data
});

export const deleteLandingPage = id => ({
  type: types.DELETE_LANDING_PAGE,
  id
});

export const getCategories = () => ({
  type: types.FETCH_CATEGORIES
});

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload
});

export const setSuccess = payload => ({
  type: types.SUCCESS_EDITOR,
  payload
});

export const changeShockAction = payload => ({
  type: types.CHANGE_SHOCK_ACTION,
  payload
});

export const changeHomePagePosition = payload => ({
  type: types.CHANGE_HOME_PAGE_POSITION,
  payload
});
