/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Choose from './components/Choose';
import img1 from '../../assets/images/cms_LandingPage/landing_page_1.jpeg';
import img2 from '../../assets/images/cms_LandingPage/landing_page_2.jpeg';
import img3 from '../../assets/images/cms_LandingPage/landing_page_3.jpeg';
import Editor from './Editor';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModels } from './redux/actions';
import { makeSelectModels } from './redux/selectors';

const key = 'landing_page';

const NewLandingPage = ({ newBool }) => {
  const [option, setOption] = useState(0);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const models = useSelector(makeSelectModels());

  useEffect(() => {
    dispatch(getModels());
  }, []);

  useEffect(() => {
  }, [models]);

  return (
    <>
      <div
        className="landing_page_cms"
        style={
          option !== 0
            ? { visibility: 'hidden', height: '0px', margin: '0px' }
            : {}
        }
      >
        <Choose options={models} setOption={setOption} />
      </div>

      <Editor option={option} newBool={newBool} />
    </>
  );
};

export default NewLandingPage;
