const KEY = '[CMS_CATEGORIES]';

const types = {
  SET_PARAMS: `${KEY} SET_PARAMS`,
  CLEAR_PARAMS: `${KEY} CLEAR_PARAMS`,

  CHANGE_ORDER_REQUEST: `${KEY} CHANGE_ORDER_REQUEST`,

  FETCH_CATEGORIES: `${KEY} FETCH_CATEGORIES`,
  SET_CATEGORIES: `${KEY} SET_CATEGORIES`,

  FETCH_SINGLE_CATEGORY: `${KEY} FETCH_SINGLE_CATEGORY`,
  SET_SINGLE_CATEGORY: `${KEY} SET_SINGLE_CATEGORY`,
  CLEAR_SINGLE_CATEGORY: `${KEY} CLEAR_SINGLE_CATEGORY`,
  FETCH_SINGLE_CATEGORY_ERROR: `${KEY} FETCH_SINGLE_CATEGORY_ERROR`,

  ADD_CATEGORY_REQUEST: `${KEY} ADD_CATEGORY_REQUEST`,
  ADD_CATEGORY_SUCCESS: `${KEY} ADD_CATEGORY_SUCCESS`,
  ADD_CATEGORY_ERROR: `${KEY} ADD_CATEGORY_ERROR`,

  UPDATE_CATEGORY_REQUEST: `${KEY} UPDATE_CATEGORY_REQUEST`,
  UPDATE_CATEGORY_SUCCESS: `${KEY} UPDATE_CATEGORY_SUCCESS`,
  UPDATE_CATEGORY_ERROR: `${KEY} UPDATE_CATEGORY_ERROR`,

  DELETE_CATEGORY_REQUEST: `${KEY} DELETE_CATEGORY_REQUEST`,

  UPDATE_IMAGE: `${KEY} UPDATE_IMAGE`,
  DELETE_IMAGE: `${KEY} DELETE_IMAGE`,

  UPDATE_DECLARATION: `${KEY} UPDATE_DECLARATION`,

  // Attributes
  CREATE_ATTRIBUTE_REQUEST: `${KEY} CREATE_ATTRIBUTE_REQUEST`,
  ADD_ATTRIBUTE_REQUEST: `${KEY} ADD_ATTRIBUTE_REQUEST`,
  UPDATE_ATTRIBUTE_REQUEST: `${KEY} UPDATE_ATTRIBUTE_REQUEST`,
  DELETE_ATTRIBUTE_REQUEST: `${KEY} DELETE_ATTRIBUTE_REQUEST`,
  CHANGE_ATTRIBUTE_ORDER_TABLE_REQUEST: `${KEY} CHANGE_ATTRIBUTE_ORDER_TABLE_REQUEST`,
  CHANGE_ATTRIBUTE_STATUS: `${KEY} CHANGE_ATTRIBUTE_STATUS`,

  GET_ATTRIBUTE_VALUES: `${KEY} GET_ATTRIBUTE_VALUES`,
  SET_ATTRIBUTE_VALUES: `${KEY} SET_ATTRIBUTE_VALUES`,
  ADD_NEW_ATTRIBUTE_VALUE_REQUEST: `${KEY} ADD_NEW_ATTRIBUTE_VALUE_REQUEST`,
  ADD_ATTRIBUTE_VALUE_REQUEST: `${KEY} ADD_ATTRIBUTE_VALUE_REQUEST`,
  UPDATE_ATTRIBUTE_VALUE_REQUEST: `${KEY} UPDATE_ATTRIBUTE_VALUE_REQUEST`,
  DELETE_ATTRIBUTE_VALUE: `${KEY} DELETE_ATTRIBUTE_VALUE`,
  CHANGE_ATTRIBUTE_VALUES_ORDER: `${KEY} CHANGE_ATTRIBUTE_VALUES_ORDER`,
  CHANGE_ATTRIBUTE_VALUE_STATUS: `${KEY} CHANGE_ATTRIBUTE_VALUE_STATUS`,

  GET_SEARCH_STATS: `${KEY} GET_SEARCH_STATS`,
  SET_SEARCH_STATS: `${KEY} SET_SEARCH_STATS`
};

export default types;
