import React, { useState } from 'react';
import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import ActionIcons from 'cms/components/Table/ActionIcons';
import messages from './messages';
import routes from 'routes';
import MobileTableExpandButton from 'cms/components/Table/Mobile/MobileTableExpandButton';
import MobileTable from './MobileTable';

const headerItems = [
  messages.manufacturer,
  messages.category,
  messages.name,
  messages.lower
];

const TableRemove = ({ data, handleDelete }) => {
  const [opened, setOpened] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const link = id => `jakov-exclusion/${id}`;

  const onDelete = id => {
    setModal(true);
    setId(id);
  };

  return (
    <TableHelper
      tableName="conditions"
      tableHeaders={headerItems}
      modalText={messages.delete_modal_text}
      open={modal}
      close={() => setModal(false)}
      handleDelete={() => handleDelete(id)}
    >
      {data.map(item => (
        <TableRow key={item.id}>
          <MobileTableExpandButton
            id={item.id}
            opened={opened}
            setOpened={setOpened}
          />
          <TableCell>{item.since_the_day}</TableCell>
          <TableCell>{item.until_the_day}</TableCell>
          <TableCell>
            {item.categories?.map(c => c?.name)?.join(', ')}
          </TableCell>
          <TableCell>{item.brands?.map(c => c?.name)?.join(', ')}</TableCell>

          <ActionIcons
            link={link(item.id)}
            handleDelete={() => onDelete(item.id)}
          />
          <MobileTable item={item} opened={opened} />
        </TableRow>
      ))}
    </TableHelper>
  );
};

export default TableRemove;
