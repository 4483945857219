const KEY = '[CMS_LAGER]';

const types = {
  GET_CATEGORIES: `${KEY} GET_CATEGORIES`,
  SET_CATEGORIES: `${KEY} SET_CATEGORIES`,
  GET_BRANDS: `${KEY} GET_BRANDS`,
  SET_BRANDS: `${KEY} SET_BRANDS`,

  GET_CONDITIONS: `${KEY} GET_CONDITIONS`,
  GET_CONDITION: `${KEY} GET_CONDITION`,
  SET_CONDITIONS: `${KEY} SET_CONDITIONS`,
  SET_CONDITION: `${KEY} SET_CONDITION`,
  CREATE_CONDITION: `${KEY} CREATE_CONDITION`,
  UPDATE_CONDITION: `${KEY} UPDATE_CONDITION`,
  DELETE_CONDITION: `${KEY} DELETE_CONDITION`,

  GET_EXCLUSIONS: `${KEY} GET_EXCLUSIONS`,
  GET_EXCLUSION: `${KEY} GET_EXCLUSION`,
  SET_EXCLUSIONS: `${KEY} SET_EXCLUSIONS`,
  SET_EXCLUSION: `${KEY} SET_EXCLUSION`,
  CREATE_EXCLUSION: `${KEY} NEW_EXCLUSION`,
  UPDATE_EXCLUSION: `${KEY} UPDATE_EXCLUSION`,
  DELETE_EXCLUSION: `${KEY} DELETE_EXCLUSION`
};

export default types;
