import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';

const LinkButton = ({ label, link, classes }) => {
  const { push } = useHistory();
  const { formatMessage } = useIntl();

  return (
    <Button
      className={`button ${classes && classes}`}
      onClick={() => push(link)}
    >
      {formatMessage(label)}
    </Button>
  );
};

export default LinkButton;
