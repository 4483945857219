import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { camelCase, isArray, mapKeys } from 'lodash-es';
import request from 'utils/request';
import { setCategories, setSearchResults } from './actions';
import types from './constants';

const athenaAPI = axios.create({
  baseURL: 'https://eu-1.athenasearch.cloud/api/v1/'
});

athenaAPI.interceptors.response.use(
  response => mapKeys(response.data, (_, key) => camelCase(key)),
  error => Promise.reject(error.response)
);

function* searchRequest({ url, method, data, headers = {}, params }) {
  try {
    return yield call(athenaAPI, { method, url, headers, data, params });
  } catch (error) {
    throw error;
  }
}

export function* search({ q }) {
  try {
    const params = {
      q,
      token: 'wqbvdOXhW9hlS1DzAGvB'
    };

    const { data } = yield call(searchRequest, {
      url: 'autocomplete',
      method: 'get',
      params
    });

    if (!isArray(data)) yield put(setSearchResults(data));
  } catch (error) {}
}

export function* fetchCategories() {
  try {
    const { data: categories } = yield call(request, {
      url: 'categories?include=all_subcategories&main=1',
      method: 'get'
    });
    yield put(setCategories(categories));
    // yield put(setCategories(mock));
  } catch (error) {}
}

export function* firstClick() {
  try {
    const params = {
      token: 'wqbvdOXhW9hlS1DzAGvB'
    };

    const { data } = yield call(searchRequest, {
      url: 'autocomplete/first-click',
      method: 'get',
      params
    });

    yield put(setSearchResults(data));
  } catch (error) {}
}

export default function* appBarSaga() {
  yield takeLatest(types.FETCH_CATEGORIES, fetchCategories);
  yield takeLatest(types.SEARCH, search);
  yield takeLatest(types.FIRST_CLICK, firstClick);
}
