/* eslint-disable no-lone-blocks */
import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import types from './constants';
import { setProduct, setDeclaration, getProductError } from './actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import responseMock from './mockData.json';
import Axios from 'axios';
import config from '../../../config';
const BASE_URL = 'products';

export function* fetchProduct({ slug }) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${slug}?include=categories,images,discount.type,mainCategory,approvedRatings,brand,supplier,answeredQuestions.user`,
      method: 'get'
    });
    const { data: actionsData } = yield call(request, {
      url: 'catalog-stickers',
      method: 'get'
    });

    const actions = actionsData.slice(0, 2);

    const combinedData = {
      ...data,
      actions
    };

    yield put(setProduct(combinedData));
    // yield put(setProduct(responseMock.data));
  } catch (error) {
    yield put(getProductError('Proizvod ne postoji ili je izbrisan.'));
  }
}

export function* ratingProduct({ product_slug, payload, closeModal }) {
  try {
    const { data } = yield call(request, {
      url: `products/${product_slug}/ratings`,
      method: 'post',
      data: payload
    });

    yield closeModal();
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}

export function* askQuestion({ slug, payload, meta: { resetForm } }) {
  try {
    const { data } = yield call(request, {
      url: `products/${slug}/questions`,
      method: 'post',
      data: payload
    });

    yield call(resetForm, { name: '', question: '' });
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}

export function* fetchDeclaration({ slug }) {
  try {
    const { data } = yield call(request, {
      url: `categories/${slug}/declaration`,
      method: 'get'
    });

    yield put(setDeclaration(data));
  } catch (error) {}
}

export function* productAvailableAgain({ slug, payload, meta: { resetForm } }) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${slug}/available-again/subscribe`,
      method: 'post',
      data: payload
    });

    yield call(resetForm, { email: '' });
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}

export function* sendOffer({ payload }) {
  const { price, email, sku } = payload;

  if (email == null || email === '' || price == null || price === '') {
    yield put(enqueueSnackbar({ message: 'Polja su prazna.' }, 'danger'));
    return null;
  } else {
    //Provera da li je uneti email validan
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
        )
    ) {
      yield put(
        enqueueSnackbar(
          { message: 'Niste uneli pravilnu Email adresu.' },
          'danger'
        )
      );
      return null;
    }
  }

  //Uneti polja validna, sledeci korak - POST REQUEST

  let message;
  let success;

  try {
    yield Axios.post(config.api.baseUrl + 'priceoffer', {
      product_sku: sku,
      email: email,
      price: price
    })
      .then(function(response) {
        message = response.data?.message;
        if (response.data?.success === true) {
          success = true;
        } else {
          success = false;
        }
      })
      .catch(function(error) {
        console.log(error?.message);
        throw error.message;
      });
    yield console.log(message);
    if (success) {
      yield put(enqueueSnackbar({ message: 'Uspešno ste poslali upit!' }));
    } else {
      yield put(enqueueSnackbar({ message: message }, 'danger'));
    }
  } catch (error) {
    console.log(error);
    yield put(enqueueSnackbar({ message: error }, 'danger'));
  }
}

export default function* _singleProductSaga() {
  yield takeLatest(types.GET_PRODUCT, fetchProduct);
  yield takeLatest(types.RATING_PRODUCT, ratingProduct);
  yield takeLatest(types.ASK_QUESTION, askQuestion);
  yield takeLatest(types.GET_DECLARATION, fetchDeclaration);
  yield takeLatest(types.PRODUCT_AVAILABLE_AGAIN, productAvailableAgain);
  yield takeLatest(types.SEND_OFFER, sendOffer);
}
