import React from 'react';
import { useIntl } from 'react-intl';
import { activeClass } from 'helpers/activeClass';

const FormNav = ({ active, setActive, items }) => {
  const { formatMessage } = useIntl();

  return (
    <nav className="form_nav_wrapper">
      {items.map(item => (
        <div
          key={item.id}
          onClick={() => setActive(item.id)}
          className={activeClass('nav_item', item.id === active)}
        >
          {formatMessage(item.name)}
        </div>
      ))}
    </nav>
  );
};

export default FormNav;
