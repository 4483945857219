import types from './constants';

export const getProducts = payload => ({
  type: types.GET_PRODUCTS,
  payload
});

export const setProducts = payload => ({
  type: types.SET_PRODUCTS,
  payload
});

export const getProduct = product_id => ({
  type: types.GET_PRODUCT,
  product_id
});

export const getPricelist = slug => ({
  type: types.GET_PRICELIST,
  slug
});

export const setPricelist = payload => ({
  type: types.SET_PRICELIST,
  payload
});

export const setAllIds = payload => ({
  type: types.SET_ALL_IDS,
  payload
});

export const setProduct = payload => ({
  type: types.SET_PRODUCT,
  payload
});

export const clearProductData = () => ({
  type: types.CLEAR_PRODUCT_DATA
});

export const getProductError = error => ({
  type: types.GET_PRODUCT_ERROR,
  error
});

export const setParams = payload => ({
  type: types.SET_PARAMS,
  payload
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});

export const createProduct = (payload, photos, main_photo, setErrors) => ({
  type: types.CREATE_PRODUCT_REQUEST,
  payload,
  photos,
  main_photo,
  meta: { setErrors }
});

export const createProductSuccess = () => ({
  type: types.CREATE_PRODUCT_SUCCESS
});

export const createProductErrors = () => ({
  type: types.CREATE_PRODUCT_ERROR
});

export const getPhotos = product_id => ({
  type: types.GET_PHOTOS,
  product_id
});

export const getSticker = product_id => ({
  type: types.GET_STICKER,
  product_id
});

export const getCatalogSticker = product_id => ({
  type: types.GET_CATALOG_STICKER,
  product_id
});

export const setPhotos = payload => ({
  type: types.SET_PHOTOS,
  payload
});

export const setSticker = payload => ({
  type: types.SET_STICKER,
  payload
});

export const setCatalogSticker = payload => ({
  type: types.SET_CATALOG_STICKER,
  payload
});
export const sendPhotosSuccess = (id, payload, method) => ({
  type: types.SEND_PHOTOS_REQUEST,
  id,
  payload,
  method
});

export const createMainPhoto = (id, photo, method) => ({
  type: types.CREATE_MAIN_PHOTO,
  id,
  photo,
  method
});

export const removePhoto = (product_id, img_id) => ({
  type: types.REMOVE_PHOTO,
  product_id,
  img_id
});

export const updateProduct = (id, payload, setErrors) => ({
  type: types.UPDATE_PRODUCT_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const updateProductSuccess = () => ({
  type: types.UPDATE_PRODUCT_SUCCESS
});

export const updateProductError = () => ({
  type: types.UPDATE_PRODUCT_ERROR
});

// Discounts
export const createDiscounts = (id, payload, setErrors) => ({
  type: types.CREATE_DISCOUNT_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const updateDiscounts = (id, discount_id, payload, setErrors) => ({
  type: types.UPDATE_DISCOUNTS_REQUEST,
  id,
  discount_id,
  payload,
  meta: { setErrors }
});

export const deleteDiscount = (product_id, discount_id, resetForm) => ({
  type: types.DELETE_DISCOUNT_REQUEST,
  product_id,
  discount_id,
  resetForm
});

// Attributes
export const getAttributes = product_id => ({
  type: types.GET_ATTRIBUTES,
  product_id
});

export const setAttributes = payload => ({
  type: types.SET_ATTRIBUTES,
  payload
});

export const updateAttributeStatus = (product_id, attribute_id, payload) => ({
  type: types.UPDATE_ATTRIBUTE_STATUS,
  product_id,
  attribute_id,
  payload
});

export const updateAttributeValues = (product_id, attribute_id, value_id) => ({
  type: types.UPDATE_ATTRIBUTE_VALUES,
  product_id,
  attribute_id,
  value_id
});

export const createAttributeValue = (
  product_id,
  category_id,
  attribute_id,
  payload,
  close,
  setErrors,
  resetForm
) => ({
  type: types.CREATE_ATTRIBUTE_VALUE_REQUEST,
  product_id,
  category_id,
  attribute_id,
  payload,
  close,
  meta: { setErrors, resetForm }
});

export const getNewValueName = () => ({
  type: types.GET_NEW_VALUE_NAME
});

export const setNewValueName = name => ({
  type: types.SET_NEW_VALUE_NAME,
  name
});

export const clearNewValueName = () => ({
  type: types.CLEAR_NEW_VALUE_NAME
});

export const getCategoryAttributes = id => ({
  type: types.GET_CATEGORY_ATTRIBUTES,
  id
});

export const setCategoryAttributes = payload => ({
  type: types.SET_CATEGORY_ATTRIBUTES,
  payload
});

export const clearCategoryAttributes = () => ({
  type: types.CLEAR_CATEGORY_ATTRIBUTES
});

export const getCategoryAttributesError = error => ({
  type: types.GET_CATEGORY_ATTRIBUTES_ERROR,
  error
});

export const massActivateAttributes = (payload, params) => ({
  type: types.MASS_ACTIVATE_ATTRIBUTES_REQUEST,
  payload,
  params
});

export const massDeactivateAttributes = payload => ({
  type: types.MASS_DEACTIVATE_ATTRIBUTES_REQUEST,
  payload
});

export const disconnectProduct = (id, close) => ({
  type: types.DISCONNECT_PRODUCT,
  id,
  close
});

export const createAttribute = (
  category_id,
  payload,
  setErrors,
  resetForm
) => ({
  type: types.CREATE_ATTRIBUTE_REQUEST,
  category_id,
  payload,
  meta: {
    setErrors,
    resetForm
  }
});

export const addAttribute = (category_id, payload, setErrors, resetForm) => ({
  type: types.ADD_ATTRIBUTE_REQUEST,
  category_id,
  payload,
  meta: {
    setErrors,
    resetForm
  }
});

export const createBrand = (
  payload,
  toggleSwitcher,
  clearFormData,
  setDropdownValue
) => ({
  type: types.CREATE_BRAND,
  payload,
  toggleSwitcher,
  clearFormData,
  setDropdownValue
});

export const createBrandError = error => ({
  type: types.CREATE_BRAND_ERROR,
  error
});

export const getPassProducts = (payload, params, close) => ({
  type: types.GET_PASS_PRODUCTS,
  payload,
  params,
  close
});

export const setPassProducts = payload => ({
  type: types.SET_PASS_PRODUCTS,
  payload
});

export const postPassProducts = (payload, params, close) => ({
  type: types.POST_PASS_PRODUCTS,
  payload,
  params,
  close
});

export const updateDescription = (params, payload, close, clear) => ({
  type: types.UPDATE_DESCRIPTIONS,
  params,
  payload,
  close,
  clear
});

export const massUpdateDiscounts = (
  payload,
  params,
  close,
  clear,
  setErrors
) => ({
  type: types.MASS_UPDATE_DISCOUNTS,
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
});

export const getAttributeValues = (attribute_id, params) => ({
  type: types.GET_ATTRIBUTE_VALUES,
  attribute_id,
  params
});

export const setAttributeValues = payload => ({
  type: types.SET_ATTRIBUTE_VALUES,
  payload
});

export const addNewAttributeValue = (
  product_id,
  attribute_id,
  category_id,
  payload,
  close,
  setErrors
) => ({
  type: types.ADD_NEW_ATTRIBUTE_VALUE,
  product_id,
  attribute_id,
  category_id,
  payload,
  close,
  meta: { setErrors }
});

export const uploadSticker = payload => ({
  type: types.UPLOAD_STICKER,
  payload
});

export const uploadAction = payload => ({
  type: types.UPLOAD_ACTION,
  payload
});

export const uploadOneSticker = payload => ({
  type: types.UPLOAD_ONE_STICKER,
  payload
});

export const removeSticker = payload => ({
  type: types.REMOVE_STICKER,
  payload
});

export const setSpecDealPrice = (id, payload) => ({
  type: types.SET_SPEC_DEALER_PRICE,
  id,
  payload
});

export const addPromoCode = (payload, params, close, clear, setErrors) => ({
  type: types.ADD_PROMO_CODE,
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
});
